import { useEffect, useRef, useState } from "react";
import "../styles/Header.scss";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const [menu, setMenu] = useState(false);

  const navigate = useNavigate();

  const auswertenRef = useRef(null);
  const verkaufRef = useRef(null);
  const eintragenRef = useRef(null);
  const bearbeitenRef = useRef(null);
  const pdfRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 800) {
      if (menu) {
        if (window.location.pathname === "/auswerten") {
          auswertenRef.current.style.backgroundColor = "#6a869b";
        }
        if (window.location.pathname === "/verkauf") {
          verkaufRef.current.style.backgroundColor = "#6a869b";
        }
        if (window.location.pathname === "/") {
          eintragenRef.current.style.backgroundColor = "#6a869b";
        }
        if (window.location.pathname === "/bearbeiten") {
          bearbeitenRef.current.style.backgroundColor = "#6a869b";
        }
        if (window.location.pathname === "/pdf") {
          pdfRef.current.style.backgroundColor = "#6a869b";
        }
      }
    } else {
      if (window.location.pathname === "/auswerten") {
        auswertenRef.current.style.backgroundColor = "#6a869b";
      }
      if (window.location.pathname === "/verkauf") {
        verkaufRef.current.style.backgroundColor = "#6a869b";
      }
      if (window.location.pathname === "/") {
        eintragenRef.current.style.backgroundColor = "#6a869b";
      }
      if (window.location.pathname === "/bearbeiten") {
        bearbeitenRef.current.style.backgroundColor = "#6a869b";
      }
      if (window.location.pathname === "/pdf") {
        pdfRef.current.style.backgroundColor = "#6a869b";
      }
    }
  }, [menu]);

  return (
    <main className="header">
      <div className="nav">
        <div className="version">version: 2.0.0 </div>
        <div
          className="links"
          ref={auswertenRef}
          onClick={() => navigate("/auswerten")}
        >
          <i className="fa-solid fa-chart-simple"></i>
          Auswerten
        </div>
        <div
          className="links"
          ref={verkaufRef}
          onClick={() => navigate("/verkauf")}
        >
          <i className="fa-solid fa-file-invoice-dollar"></i>
          Verkauf
        </div>
        <div className="links" ref={eintragenRef} onClick={() => navigate("/")}>
          <i className="fa-solid fa-circle-plus"></i>
          Eintragen
        </div>
        <div
          className="links"
          ref={bearbeitenRef}
          onClick={() => navigate("/bearbeiten")}
        >
          <i className="fa-solid fa-pen-to-square"></i>
          Bearbeiten
        </div>
        <div className="links" ref={pdfRef} onClick={() => navigate("/pdf")}>
          <i className="fa-solid fa-file-pdf"></i>
          PDF
        </div>
      </div>

      <div className="logoutBox">
        <i
          className="fa-solid fa-arrow-right-from-bracket"
          title="Ausloggen"
          onClick={() => {
            localStorage.setItem("token", "");
            localStorage.setItem("token_type", "");
            navigate("/login");
            window.location.reload();
          }}
        ></i>
      </div>

      <i className="fa-solid fa-bars" onClick={() => setMenu(true)}></i>

      {menu && (
        <div className="menuBox">
          <i className="fa-solid fa-xmark" onClick={() => setMenu(false)}></i>
          <div className="menu"> Menu </div>

          <div className="navBox">
            <div
              className="links"
              ref={auswertenRef}
              onClick={() => navigate("/auswerten")}
            >
              <i className="fa-solid fa-chart-simple"></i>
              Auswerten
            </div>

            <div
              className="links"
              ref={verkaufRef}
              onClick={() => navigate("/verkauf")}
            >
              <i className="fa-solid fa-file-invoice-dollar"></i>
              Verkauf
            </div>

            <div
              className="links"
              ref={eintragenRef}
              onClick={() => navigate("/")}
            >
              <i className="fa-solid fa-circle-plus"></i>
              Eintragen
            </div>

            <div
              className="links"
              ref={bearbeitenRef}
              onClick={() => navigate("/bearbeiten")}
            >
              <i className="fa-solid fa-pen-to-square"></i>
              Bearbeiten
            </div>

            <div
              className="links"
              ref={pdfRef}
              onClick={() => navigate("/pdf")}
            >
              <i className="fa-solid fa-file-pdf"></i>
              PDF
            </div>

            <div
              className="links"
              onClick={() => {
                localStorage.setItem("token", "");
                localStorage.setItem("token_type", "");
                navigate("/login");
                window.location.reload();
              }}
            >
              <i
                className="fa-solid fa-arrow-right-from-bracket"
                title="Ausloggen"
              ></i>
              Auslogin
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
