import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDFFile = (data) => {
  const spalten = localStorage.getItem("Restbstand");
  let tableColumns = [];
  const arry = JSON.parse(spalten);
  if (arry) {
    arry.forEach((element) => {
      tableColumns.push(element.label);
    });
  }

  if (tableColumns.length === 0) {
    alert(
      "Es gibt keine Tabellenspalten. Bitte füge auf der Bearbeitungsseite Tabellenspalten hinzu."
    );
    return;
  }

  let totalNetto = 0;
  let totalInsuranceTax = 0;
  let totalBrutto = 0;
  let totalSellingPrice = 0;
  let totalGewinn = 0;
  let totalMange = 0;

  const tableRows = data.map((item) => {
    const mange = 1;
    const netto = item.netto ? parseFloat(item.netto).toFixed(2) : 0;
    const insuranceTax = item.insurance_tax
      ? parseFloat(item.insurance_tax).toFixed(2)
      : 0;
    const brutto = item.brutto ? parseFloat(item.brutto).toFixed(2) : 0;
    const sellingPrice = item.selling_price
      ? parseFloat(item.selling_price).toFixed(2)
      : 0;
    const gewinn =
      sellingPrice !== "-" && netto !== "-"
        ? (sellingPrice - netto).toFixed(2)
        : 0;

    totalMange += mange;
    totalNetto += parseFloat(netto);
    totalInsuranceTax += parseFloat(insuranceTax);
    totalBrutto += parseFloat(brutto);

    return [mange, item.number, netto, insuranceTax, brutto];
  });

  const summaryRow = [
    totalMange,
    "",
    totalNetto.toFixed(2) + " €",
    totalInsuranceTax.toFixed(2) + " €",
    totalBrutto.toFixed(2) + " €",
  ];
  tableRows.push(summaryRow);

  const doc = new jsPDF();

  const styles = {
    fontSize: 10,
    fontStyle: "italic",
    font: "helvetica",
    textColor: [0, 0, 0], // Schwarz
    lineWidth: 0.1,
    lineColor: [0, 0, 0], // Schwarz
    fillStyle: "DF", // DrawFill
  };

  const headStyles = {
    fillColor: [255, 255, 255], // Weiß
    textColor: [0, 0, 0], // Schwarz
  };

  const bodyStyles = {
    fillColor: [255, 255, 255], // Weiß
    textColor: [0, 0, 0], // Schwarz
  };

  const tableStartY = 5;

  doc.autoTable({
    head: [tableColumns],
    body: tableRows,
    didDrawPage: function (data) {
      doc.setFont("times", "normal");
      doc.setFontSize(10);
      const pageHeight = doc.internal.pageSize.height;
      const date = new Date().toLocaleDateString();
      const footerText = `Datum: ${date}`;
      const textWidth = doc.getTextWidth(footerText);
      const textX = (doc.internal.pageSize.width - textWidth) / 2;
      const textY = pageHeight - 10;

      doc.text(footerText, textX, textY);

      const tableBottom = data.table.finalY + tableStartY;
      if (tableBottom >= pageHeight - 20) {
        doc.addPage();
      }
    },
    startY: tableStartY,
    styles: styles,
    headStyles: headStyles,
    bodyStyles: bodyStyles,
  });

  const blob = new Blob([doc.output("blob")], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  window.open(url, "_blank");
};

export { generatePDFFile };
