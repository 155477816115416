import "../styles/Home.scss";
import Header from "../components/header";
import { useState, useEffect, useRef } from "react";
import Calendar from "color-calendar";
import { useContextProvider } from "../context/context";
import axios from "../axios";
import "../styles/eintragen.scss";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { German } from "flatpickr/dist/l10n/de.js";
import config from "../config";

const Eintragen = () => {
  const { getInsurances, getProducts, insurances, products, verifyToken } =
    useContextProvider();

  const ArtikelBzRef = useRef();
  let InsuranceId = null;

  const [showtextarea, setShowtextarea] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarValue, setCalendarValue] = useState("");
  const [StartArtNr, setStartArtNr] = useState(0);
  const [EndArtNr, setEndArtNr] = useState(0);
  const [gleich, setGleich] = useState(0);
  const [ArtikelArt, setArtikelArt] = useState("");
  const [eVB, seteVB] = useState("");
  const [Netto, setNetto] = useState("");
  const [Steuer, setSteuer] = useState("");
  const [Brutto, setBrutto] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [textarea, setTextarea] = useState("");
  const [luod, setLuod] = useState(false);

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    if (Netto) {
      setNetto(Netto.replace(/,/g, "."));
    }

    if (Steuer) {
      setSteuer(Steuer.replace(/,/g, "."));
    }
    if (Brutto) {
      setBrutto(Brutto.replace(/,/g, "."));
    }
  }, [Brutto, Steuer, Netto]);

  useEffect(() => {
    if (!showtextarea) {
      setSelectedDate(new Date());
      setEndArtNr(0);
      setStartArtNr(0);
      setArtikelArt("");
      seteVB("");
      setNetto("");
      setSteuer("");
      setBrutto("");
    }
  }, [showtextarea]);

  useEffect(() => {
    if (textarea) {
      const x = JSON.parse(textarea?.trim());
      const arr = x.split("\n");
      const keys = [
        "import_datum",
        "Artikelbezeichnung",
        "Artikel_Art",
        "Artikel_Nr",
        "eVB",
        "Netto",
        "Vers_Steuer",
        "Brutto",
      ];

      let dataObj;
      let cunt;
      let Start;
      let end;
      let eVB = "";

      const dataArray = arr.map((line, i) => {
        cunt = i;
        return line.split("\t\t");
      });
      const result = dataArray.map((entry, i) => {
        if (i == 0) {
          Start = entry[3];
          eVB += `${entry[4]}`;
        } else {
          eVB += `,${entry[4]}`;
        }
        end = entry[3];
        const obj = {};
        keys.forEach((key, index) => {
          obj[key] = entry[index];
        });
        return obj;
      });

      dataObj = result[1];
      const datum = dataObj?.import_datum.split(".");
      if (dataObj) {
        setSelectedDate(`${datum[2]}-${datum[1]}-${datum[0]}`);
        setEndArtNr(Number(end));
        setStartArtNr(Number(Start));
        setArtikelArt(dataObj.Artikel_Art);
        seteVB(eVB);
        setNetto(dataObj.Netto);
        setSteuer(dataObj.Vers_Steuer);
        setBrutto(dataObj.Brutto?.trim());
      } else {
        setSelectedDate(new Date());
        setEndArtNr(0);
        setStartArtNr(0);
        setArtikelArt("");
        seteVB("");
        setNetto("");
        setSteuer("");
        setBrutto("");
      }
    }
  }, [textarea]);

  useEffect(() => {
    if ((StartArtNr, EndArtNr)) {
      const num = Number(EndArtNr) - Number(StartArtNr) + 1;
      setGleich(num);
    } else {
      setGleich(0);
    }
  }, [StartArtNr, EndArtNr]);

  const addNewInsurance = async () => {
    let exists = false;

    insurances.forEach((element) => {
      if (element.number == ArtikelArt) {
        InsuranceId = element.id;
        exists = true;
      }
    });

    if (!exists) {
      const data = {
        number: ArtikelArt,
      };
      const jsonData = JSON.stringify(data);
      const token = localStorage.getItem("token");
      const tokenType = localStorage.getItem("token_type");
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${tokenType} ${token}`,
      };

      try {
        const res = await axios.post("/insurances", jsonData, {
          headers: headers,
        });
        if (res) {
          InsuranceId = res.data.id;
          getInsurances();
          NotificationManager.success(
            "Neue Versicherung wurde erfolgreich erstellt",
            "",
            3000
          );
        }
      } catch (error) {
        const msg = error.request.responseText.split(",")[0].split(":")[1];
        NotificationManager.error(msg, error.code, 5000);
      }
      return;
    }
  };

  const eintragen = async (e) => {
    await verifyToken();
    setTableData([]);
    setLuod(true);

    let numberList = [];
    for (let i = Number(StartArtNr); i <= Number(EndArtNr); i++) {
      numberList.push(i);
    }
    const article_numbers = numberList.join(",");
    const array = [];
    for (let index = 0; index <= article_numbers.length; index += 200) {
      array.push(article_numbers.substring(index, index + 200));
    }

    await addNewInsurance();

    const token = localStorage.getItem("token");
    const tokenType = localStorage.getItem("token_type");
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${tokenType} ${token}`,
    };

    try {
      let status = null;
      for (const chunk of array) {
        const data = {
          product_id: Number(ArtikelBzRef.current.value),
          insurance_id: InsuranceId,
          number: chunk.replace(/^,|,$/g, ""),
          number_count: chunk.replace(/^,|,$/g, "").split(",").length,
          evb: eVB,
          netto: `${Number(Netto).toFixed(2)}`,
          insurance_tax: `${Number(Steuer).toFixed(2)}`,
          brutto: `${Number(Brutto).toFixed(2)}`,
          import_date: convertDate(selectedDate),
        };
        const jsonData = JSON.stringify(data);
        const res = await axios.post("/articles", jsonData, {
          headers: headers,
        });
        if (res.data) {
          setTableData((tableData) => [...tableData, ...res.data]);
          status = true;
        }
      }
      if (status) {
        setArtikelArt("");
        setStartArtNr("");
        seteVB("");
        setNetto("");
        setSteuer("");
        setBrutto("");
        setStartArtNr(0);
        setEndArtNr(0);
        setGleich(0);
        setShowtextarea(false);
        NotificationManager.success(
          "neue Tikets wurde erfolgreich erstellt",
          "",
          3000
        );
      }
    } catch (error) {
      const msg = error.request.responseText.split(",")[0].split(":")[1];
      NotificationManager.error(msg, error.code, 5000);
    } finally {
      setLuod(false);
    }
  };

  function convertDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <Header />
      <div
        className="mainbox"
        onClick={(e) => {
          setShowCalendar(false);
        }}
      >
        <div className="verkaufForm">
          <div className="felderBox Erhaltsdatum">
            <label className="label">import-datum</label>

            <Flatpickr
              className="calendar"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date[0])}
              options={{
                enableTime: false,
                dateFormat: "Y-m-d",
                altInput: false,
                altFormat: "F j, Y",
                time_24hr: false,
                locale: German,
              }}
            />
          </div>
          <div className="felderBox Artikelbezeichnung">
            <label className="label">Artikelbezeichnung</label>
            <select ref={ArtikelBzRef} defaultValue={products[0]?.id}>
              {products.map((product, i) => (
                <option value={product.id} key={product.id}>
                  {product.title}
                </option>
              ))}
            </select>
          </div>
          <div className="felderBox ArtikelArt">
            <label className="label">Artikel Art</label>
            <input
              type="text"
              value={ArtikelArt}
              onChange={(e) => {
                setArtikelArt(e.target.value);
              }}
            />
          </div>
          <div className="felderBox Start">
            <label className="label">Start Art. Nr.</label>
            <div className="cont">
              <input
                type="text"
                value={StartArtNr}
                onChange={(e) => setStartArtNr(e.target.value)}
              />
              <label>End Art. Nr.</label>
              <input
                type="text"
                value={EndArtNr}
                onChange={(e) => setEndArtNr(e.target.value)}
              />
              <label>gleich</label>
              <input type="text" value={gleich} onChange={() => {}} />
            </div>
          </div>
          <div className="felderBox">
            <label className="label">eVB</label>
            <input
              type="text"
              value={eVB}
              onChange={(e) => seteVB(e.target.value)}
            />
          </div>
          <div className="felderBox">
            <label className="label">Netto</label>
            <input
              type="text"
              value={Netto}
              onChange={(e) => setNetto(e.target.value)}
            />
          </div>
          <div className="felderBox">
            <label className="label">Vers. Steuer</label>
            <input
              type="text"
              value={Steuer}
              onChange={(e) => setSteuer(e.target.value)}
            />
          </div>
          <div className="felderBox">
            <label className="label">Brutto</label>
            <input
              type="text"
              value={Brutto}
              onChange={(e) => setBrutto(e.target.value)}
            />
          </div>

          <div className="btnBox ">
            <button className="Eintragen" onClick={eintragen}>
              {luod ? <i class="fa-solid fa-circle-notch"></i> : "Eintragen"}
            </button>
            <button
              className="import"
              onClick={() => setShowtextarea(!showtextarea)}
            >
              import info
              <i className="fa-solid fa-arrow-down"></i>
            </button>
          </div>
        </div>

        {showtextarea && (
          <>
            <div className="titles">
              import-datum _ Artikelbezeichnung _ Artikel Art _ Artikel Nr _ eVB
              _ Netto _ Vers. Steuer _ Brutto{" "}
            </div>
            <textarea
              placeholder="Bitte kopieren Sie alle Zellen aus Ihrem Excel-Sheet und fügen es hier ein ... "
              onChange={(e) => {
                setTextarea(JSON.stringify(e.target.value));
              }}
            ></textarea>
          </>
        )}
        {tableData.length > 1 && (
          <div className="dataTable">
            <div className="tablecolumn">
              <div className="datum">Import-Datum</div>
              <div className="datum">Verkaufs-Art</div>
              <div className="datum">Verkaufs-Datum</div>
              <div className="datum">Zahlungs-Datum</div>
              <div className="datum">Storno/Retour-Datum</div>
              <div className="datum">Verlust-Datum</div>
              <div className="menge">Menge</div>
              <div className="arBz">Art. Bezeichnung</div>
              <div className="arAr">Artk.Art</div>
              <div className="arNr"> Art. Nr.</div>
              <div className="eVB">eVB</div>
              <div className="netto">Netto</div>
              <div className="st">Vers. St.</div>
              <div className="brutto">Brutto</div>
              <div className="gewinn">Gewinn</div>
              <div className="verkaufpreis">Verkaufpreis</div>
              <div className="verkaufpreis">status</div>
            </div>
            <div className="tableInfo">
              {tableData.map((article, i) => {
                return (
                  <div className="tableRow" key={i}>
                    <div className="datum">
                      {article.import_date
                        ? article.import_date?.split(" ")[0]
                        : "-"}
                    </div>
                    <div className="datum">
                      {article.paymentMethod
                        ? article.paymentMethod.title
                        : "-"}
                    </div>
                    <div className="datum">
                      {article.sale_date
                        ? article.sale_date?.split(" ")[0]
                        : "-"}
                    </div>
                    <div className="datum">
                      {article.payment_date
                        ? article.payment_date?.split(" ")[0]
                        : "-"}
                    </div>
                    <div className="datum">
                      {article.cancellation_date
                        ? article.cancellation_date.split(" ")[0]
                        : "-"}{" "}
                    </div>
                    <div className="datum">
                      {article.loss_date
                        ? article.loss_date.split(" ")[0]
                        : "-"}
                    </div>
                    <div className="menge">1</div>
                    <div className="arBz">
                      {article.product_id
                        ? products.filter((e) => e.id == article.product_id)[0]
                            .title
                        : "-"}
                    </div>
                    <div className="arAr">
                      {article.insurance_id
                        ? insurances.filter(
                            (e) => e.id == article.insurance_id
                          )[0].number
                        : "-"}
                    </div>
                    <div className="arNr">
                      {article.number ? article.number : "-"}
                    </div>
                    <div className="eVB">{article.evb ? article.evb : "-"}</div>
                    <div className="netto">
                      {article.netto ? article.netto : "-"}
                    </div>
                    <div className="st">
                      {article.insurance_tax ? article.insurance_tax : "-"}
                    </div>
                    <div className="brutto">
                      {article.brutto ? article.brutto : "-"}
                    </div>
                    <div className="gewinn">-</div>
                    <div className="verkaufpreis">-</div>
                    <div>
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="calc">
              <div>
                {" "}
                <span>Menge:</span> {tableData.length}{" "}
              </div>
              <div>
                {" "}
                <span>Netto:</span> {tableData[0].netto * tableData.length}{" "}
              </div>
              <div>
                {" "}
                <span>Vers. St.:</span>{" "}
                {tableData[0].insurance_tax * tableData.length}{" "}
              </div>
              <div>
                {" "}
                <span>Brutto:</span> {tableData[0].brutto * tableData.length}{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Eintragen;
