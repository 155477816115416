import { useState, useEffect } from "react";
import "./App.scss";
import "color-calendar/dist/css/theme-glass.css";
import "color-calendar/dist/css/theme-basic.css";
import Login from "./pages/login";
import Eintragen from "./pages/eintragen";
import { useNavigate } from "react-router-dom";
import Auswerten from "./pages/auswerten";
import Verkauf from "./pages/verkauf";
import Bearbeiten from "./pages/bearbeiten";
import PDF from "./pages/pdf";
import { Routes, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

export default function App() {
  return (
    <>
      <main className="verkaufPage">
        <NotificationContainer />

        <Routes>
          <Route path="/" element={<Eintragen />} />
          <Route path="/login" element={<Login />} />
          <Route path="/auswerten" element={<Auswerten />} />
          <Route path="/verkauf" element={<Verkauf />} />
          <Route path="/bearbeiten" element={<Bearbeiten />} />
          <Route path="/pdf" element={<PDF />} />
        </Routes>
      </main>
    </>
  );
}
