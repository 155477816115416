import { useState, useEffect, useRef } from "react";
import "../styles/editCom.scss";
import { useContextProvider } from "../context/context";
import axios from "../axios";
import Calendar from "color-calendar";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { German } from "flatpickr/dist/l10n/de.js";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

const Edit = (props) => {
  const { products, paymentMethods, token, tokenType, articles, setArticles } =
    useContextProvider();
  const selectRef = useRef();
  const VerkaufsArtSelectRef = useRef();
  const [importDate, setImportDate] = useState();
  const [verkaufsDatum, setVerkaufsDatum] = useState("");
  const [zahlungsDatum, setZahlungsDatum] = useState("");
  const [stornoRetourDatum, setStornoRetourDatum] = useState("");
  const [verlustDatum, setVerlustDatum] = useState("");
  const [ArtikelArt, setArtikelArt] = useState("");
  const [ArtikelBz, setArtikelBz] = useState("");
  const [eVB, seteVB] = useState("");
  const [Netto, setNetto] = useState("");
  const [Steuer, setSteuer] = useState("");
  const [Brutto, setBrutto] = useState("");
  const [Verkaufspreis, setVerkaufspreis] = useState("");
  const [elementData, setelementData] = useState();
  const [gewinn, setGewinn] = useState("");
  const [menge, setMenge] = useState("");
  const [artikelNr, setArtikelNr] = useState("");
  const [VerkaufsArt, setVerkaufsArt] = useState(true);

  useEffect(() => {
    if (Netto) {
      setNetto(Netto.replace(/,/g, "."));
    }

    if (Steuer) {
      setSteuer(Steuer.replace(/,/g, "."));
    }
    if (Brutto) {
      setBrutto(Brutto.replace(/,/g, "."));
    }
    if (Verkaufspreis) {
      setVerkaufspreis(Verkaufspreis.replace(/,/g, "."));
    }

    if (gewinn) {
      setGewinn(gewinn.replace(/,/g, "."));
    }
  }, [Brutto, Steuer, Netto, Verkaufspreis, gewinn]);

  useEffect(() => {
    setVerlustDatum(props.element.loss_date?.split(" ")[0]);
    setStornoRetourDatum(props.element.cancellation_date?.split(" ")[0]);
    setZahlungsDatum(props.element.payment_date?.split(" ")[0]);
    setImportDate(props.element.import_date?.split(" ")[0]);
    setArtikelBz(props.element.product_id);
    selectRef.current.value = props.element.product_id;
    setArtikelArt(props.element.insurance_id);
    setArtikelNr(props.element.number);
    setMenge("1");
    seteVB(props.element.evb);
    setNetto(props.element.netto);
    setSteuer(props.element.insurance_tax);
    setBrutto(props.element.brutto);
    setVerkaufsDatum(props.element.sale_date?.split(" ")[0]);
    setGewinn(
      (
        Number(props.element.selling_price) - Number(props.element.brutto)
      ).toLocaleString()
    );
    setVerkaufspreis(props.element.selling_price);
    const method = paymentMethods.filter((e) => {
      return e.id == props.element.payment_method_id;
    });
    VerkaufsArtSelectRef.current.value = method[0]
      ? JSON.stringify(method[0])
      : "Ohne";
  }, []);

  const editElement = async () => {
    let data;

    if (VerkaufsArt == "Ohne") {
      data = {
        id: props.element.id,
        product_id: selectRef.current.value
          ? Number(selectRef.current.value)
          : null,
        insurance_id: ArtikelArt ? ArtikelArt : null,
        number: artikelNr ? artikelNr : null,
        number_count: menge ? Number(menge) : null,
        evb: eVB ? eVB : null,
        netto: Netto ? Number(Netto).toFixed(2) : null,
        insurance_tax: Steuer ? Number(Steuer).toFixed(2) : null,
        brutto: Brutto ? Number(Brutto).toFixed(2) : null,
        payment_method_id: null,
        sale_date: null,
        payment_date: null,
        selling_price: null,
        cancellation_date: stornoRetourDatum
          ? convertDate(stornoRetourDatum)
          : null,
        loss_date: verlustDatum ? convertDate(verlustDatum) : null,
        import_date: importDate ? convertDate(importDate) : null,
      };
    } else {
      if (VerkaufsArt) {
        data = {
          id: props.element.id,
          product_id: selectRef.current.value
            ? Number(selectRef.current.value)
            : null,
          insurance_id: ArtikelArt ? ArtikelArt : null,
          number: artikelNr ? artikelNr : null,
          number_count: menge ? Number(menge) : null,
          evb: eVB ? eVB : null,
          netto: Netto ? Number(Netto).toFixed(2) : null,
          insurance_tax: Steuer ? Number(Steuer).toFixed(2) : null,
          brutto: Brutto ? Number(Brutto).toFixed(2) : null,
          payment_method_id:
            VerkaufsArtSelectRef.current.value != "Ohne"
              ? JSON.parse(VerkaufsArtSelectRef.current.value).id
              : null,
          selling_price: Verkaufspreis
            ? Number(Verkaufspreis).toFixed(2)
            : null,
          sale_date: verkaufsDatum ? convertDate(verkaufsDatum) : null,
          payment_date: zahlungsDatum ? convertDate(zahlungsDatum) : null,
          cancellation_date: stornoRetourDatum
            ? convertDate(stornoRetourDatum)
            : null,
          loss_date: verlustDatum ? convertDate(verlustDatum) : null,
          import_date: importDate ? convertDate(importDate) : null,
        };
      } else {
        data = {
          id: props.element.id,
          product_id: selectRef.current.value
            ? Number(selectRef.current.value)
            : null,
          insurance_id: ArtikelArt ? ArtikelArt : null,
          number: artikelNr ? artikelNr : null,
          number_count: menge ? Number(menge) : null,
          evb: eVB ? eVB : null,
          netto: Netto ? Number(Netto).toFixed(2) : null,
          insurance_tax: Steuer ? Number(Steuer).toFixed(2) : null,
          brutto: Brutto ? Number(Brutto).toFixed(2) : null,
          payment_method_id:
            VerkaufsArtSelectRef.current.value != "Ohne"
              ? JSON.parse(VerkaufsArtSelectRef.current.value).id
              : null,
          selling_price: Verkaufspreis
            ? Number(Verkaufspreis).toFixed(2)
            : null,
          cancellation_date: stornoRetourDatum
            ? convertDate(stornoRetourDatum)
            : null,
          loss_date: verlustDatum ? convertDate(verlustDatum) : null,
          import_date: importDate ? convertDate(importDate) : null,
        };
      }
    }

    const jsonData = JSON.stringify(data);
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const res = await axios.patch(`/articles`, jsonData, {
        headers: headers,
      });
      if (res) {
        const editedData = articles.map((article, i) => {
          if (article.id === data.id) {
            return data;
          } else {
            return article;
          }
        });

        setArticles(editedData);

        NotificationManager.success(
          `Element mit der ID: ${props.element.id} erfolgreich geändert`,
          "",
          3000
        );
        props.setEdit(false);
      }
    } catch (error) {
      const msg = error.request.responseText.split(",")[0].split(":")[1];
      NotificationManager.error(msg, error.code, 5000);
      console.log(error);
    }
  };

  function convertDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="editCom">
      <NotificationContainer />

      <div className="verkaufForm">
        <i
          className="fa-solid fa-xmark"
          onClick={() => props.setEdit({ status: false, i: 0 })}
        ></i>
        <div className="felderBox Erhaltsdatum">
          <label className="label">Import-Datum</label>
          <Flatpickr
            className="calendar"
            value={importDate}
            onChange={(date) => setImportDate(date[0])}
            options={{
              enableTime: false,
              dateFormat: "Y-m-d",
              altInput: false,
              altFormat: "F j, Y",
              time_24hr: false,
              locale: German,
            }}
          />
        </div>

        <div className="felderBox Erhaltsdatum">
          <label className="label">
            Verkaufs Datum <span>*</span>{" "}
          </label>
          <Flatpickr
            className="calendar"
            value={verkaufsDatum}
            onChange={(date) => setVerkaufsDatum(date[0])}
            options={{
              enableTime: false,
              dateFormat: "Y-m-d",
              altInput: false,
              altFormat: "F j, Y",
              time_24hr: false,
            }}
          />
        </div>

        <div className="felderBox Erhaltsdatum">
          <label className="label">
            Zahlungs-Datum
            {VerkaufsArt ? <span>*</span> : null}
          </label>
          <Flatpickr
            className="calendar"
            value={zahlungsDatum}
            onChange={(date) => setZahlungsDatum(date[0])}
            options={{
              enableTime: false,
              dateFormat: "Y-m-d",
              altInput: false,
              altFormat: "F j, Y",
              time_24hr: false,
            }}
          />
        </div>

        <div className="felderBox Erhaltsdatum">
          <label className="label">Storno/Retour-Datum</label>
          <Flatpickr
            className="calendar"
            value={stornoRetourDatum}
            onChange={(date) => setStornoRetourDatum(date[0])}
            options={{
              enableTime: false,
              dateFormat: "Y-m-d",
              altInput: false,
              altFormat: "F j, Y",
              time_24hr: false,
            }}
          />
        </div>

        <div className="felderBox Erhaltsdatum">
          <label className="label">Verlust-Datum</label>
          <Flatpickr
            className="calendar"
            value={verlustDatum}
            onChange={(date) => setVerlustDatum(date[0])}
            options={{
              enableTime: false,
              dateFormat: "Y-m-d",
              altInput: false,
              altFormat: "F j, Y",
              time_24hr: false,
            }}
          />
        </div>

        <div className="felderBox Artikelbezeichnung">
          <label className="label">Artikelbezeichnung</label>
          <select ref={selectRef}>
            {products.map((product, i) => {
              return (
                <option value={product.id} key={i}>
                  {product.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="felderBox ArtikelArt">
          <label className="label">Artikel Art </label>
          <input
            type="text"
            value={ArtikelArt}
            onChange={(e) => setArtikelArt(e.target.value)}
          />
        </div>

        <div className="felderBox ">
          <label className="label">Artikel Nr </label>
          <input
            type="text"
            value={artikelNr}
            onChange={(e) => setArtikelNr(e.target.value)}
          />
        </div>

        <div className="felderBox">
          <label className="label">Menge</label>
          <input
            type="text"
            value={menge}
            onChange={(e) => setMenge(e.target.value)}
          />
        </div>

        <div className="felderBox">
          <label className="label">eVB</label>
          <input
            type="text"
            value={eVB}
            onChange={(e) => seteVB(e.target.value)}
          />
        </div>

        <div className="felderBox">
          <label className="label">Netto</label>
          <input
            type="text"
            value={Netto}
            onChange={(e) => setNetto(e.target.value)}
          />
        </div>

        <div className="felderBox">
          <label className="label">Vers. Steuer</label>
          <input
            type="text"
            value={Steuer}
            onChange={(e) => setSteuer(e.target.value)}
          />
        </div>

        <div className="felderBox">
          <label className="label">Brutto</label>
          <input
            type="text"
            value={Brutto}
            onChange={(e) => setBrutto(e.target.value)}
          />
        </div>

        <div className="felderBox">
          <label className="label">Gewinn</label>
          <input type="text" value={gewinn} onChange={() => {}} />
        </div>

        <div className="felderBox">
          <label className="label">
            Verkaufspreis <span>*</span>
          </label>
          <input
            type="text"
            value={Verkaufspreis}
            onChange={(e) => setVerkaufspreis(e.target.value)}
          />
        </div>

        <div className="felderBox">
          <label className="label">
            Verkaufs. Art <span>*</span>
          </label>
          <select
            ref={VerkaufsArtSelectRef}
            onChange={(e) => {
              if (e.target.value == "Ohne") {
                setVerkaufsArt("Ohne");
              } else {
                if (JSON.parse(e.target.value).auto_payment_date) {
                  setVerkaufsArt(true);
                } else {
                  setVerkaufsArt(false);
                }
              }
            }}
          >
            <option value="Ohne">Ohne</option>
            {paymentMethods.map((method, i) => {
              return (
                <option value={JSON.stringify(method)} key={method.id}>
                  {method.title} {method.auto_payment_date ? "✔️" : null}
                </option>
              );
            })}
          </select>
        </div>

        <div className="btnBox ">
          <button className="Eintragen" onClick={editElement}>
            Speichern
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
