const oldToNewHeaders = {
  import_date: "Import-Datum",
  payment_method_id: "Verkaufs-Art",
  sale_date: "Verkaufs-Datum",
  payment_date: "Zahlungs-Datum",
  cancellation_date: "Storno/Retour-Datum",
  loss_date: "Verlust-Datum",
  product_id: "Art.Bezeichnung",
  insurance_id: "Artk.Art",
  number: "Art.Nr.",
  evb: "eVB",
  netto: "Netto",
  insurance_tax: "Vers.St.",
  brutto: "Brutto",
  selling_price: "Verkaufpreis",
};

const arrayToCSV = (dataArray, products, paymentMethods, insurances) => {
  if (!dataArray || dataArray.length === 0) {
    console.error("No data provided or data is empty.");
    return "";
  }

  const csvRows = [];
  const headers = Object.keys(dataArray[0]);

  const filteredHeaders = headers.filter((header) => header !== "id");
  const newHeaders = filteredHeaders.map(
    (header) => oldToNewHeaders[header] || header
  );

  newHeaders.push("Gewinn");
  newHeaders.push("Menge");
  csvRows.push(newHeaders.join(","));

  for (const obj of dataArray) {
    const values = filteredHeaders.map((header) => {
      let value = obj[header];

      if (header === "product_id") {
        const x = products.filter((e) => e.id === value);
        if (x.length) {
          value = x[0].title;
        }
      }

      if (header === "payment_method_id") {
        const x = paymentMethods.filter((e) => e.id === value);
        if (x.length) {
          value = x[0].title;
        }
      }

      if (header === "insurance_id") {
        const x = insurances.filter((e) => e.id === value);
        if (x.length) {
          value = x[0].number;
        }
      }

      if (value === null || value === undefined) {
        value = "";
      }

      const escapedValue = String(value).replace(/"/g, '""');
      return `"${escapedValue}"`;
    });

    const sellingPrice = parseFloat(obj["selling_price"]) || 0;
    const gewinn = sellingPrice ? sellingPrice - parseFloat(obj["brutto"]) : 0;
    const menge = 1;
    values.push(`"${gewinn.toFixed(2)}"`);
    values.push(`"${menge}"`);
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
};

const downloadCSV = (csv, filename = "data.csv") => {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const CsvFile = (data, products, paymentMethods, insurances) => {
  const csvData = arrayToCSV(data, products, paymentMethods, insurances);

  if (csvData) {
    downloadCSV(csvData);
  }
};

export default CsvFile;
