import Header from "../components/header";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useContextProvider } from "../context/context";

import "../styles/pdf.scss";

const PDF = () => {
  const { verifyToken } = useContextProvider();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [selectedOptions3, setSelectedOptions3] = useState([]);

  const options = [
    { value: "import_date", label: "Import-Datum" },
    { value: "payment_method", label: "Verkaufs-Art" },
    { value: "sale_date", label: "Verkaufs-Datum" },
    { value: "payment_date", label: "Zahlungs-Datum" },
    { value: "cancellation_date", label: "Storno/Retour-Datum" },
    { value: "loss_date", label: "Verlust-Datum" },
    { value: "menge", label: "Menge" },
    { value: "product_title", label: "Art. Bezeichnung" },
    { value: "insurance_title", label: "Artk.Art" },
    { value: "number", label: "Art. Nr." },
    { value: "evb", label: "eVB" },
    { value: "netto", label: "Netto" },
    { value: "insurance_tax", label: "Vers. St." },
    { value: "brutto", label: "Brutto" },
    { value: "gewinn", label: "Gewinn" },
    { value: "selling_price", label: "Verkaufpreis" },
  ];

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("Restbstand");
    setSelectedOptions(JSON.parse(data));
    const data1 = localStorage.getItem("Verkaufsarten");
    setSelectedOptions1(JSON.parse(data1));
    const data2 = localStorage.getItem("Storno");
    setSelectedOptions2(JSON.parse(data2));
    const data3 = localStorage.getItem("Verlust");
    setSelectedOptions3(JSON.parse(data3));
  }, []);

  useEffect(() => {
    if (selectedOptions) {
      localStorage.setItem("Restbstand", JSON.stringify(selectedOptions));
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (selectedOptions1) {
      localStorage.setItem("Verkaufsarten", JSON.stringify(selectedOptions1));
    }
  }, [selectedOptions1]);

  useEffect(() => {
    if (selectedOptions2) {
      localStorage.setItem("Storno", JSON.stringify(selectedOptions2));
    }
  }, [selectedOptions2]);

  useEffect(() => {
    if (selectedOptions3) {
      localStorage.setItem("Verlust", JSON.stringify(selectedOptions3));
    }
  }, [selectedOptions3]);

  const handleSelectChange = (selectedOptions) => {
    localStorage.setItem("Restbstand", JSON.stringify(selectedOptions));
    setSelectedOptions(selectedOptions);
  };

  const handleSelectChange1 = (selectedOptions1) => {
    setSelectedOptions1(selectedOptions1);
  };

  const handleSelectChange2 = (selectedOptions2) => {
    setSelectedOptions2(selectedOptions2);
  };

  const handleSelectChange3 = (selectedOptions3) => {
    setSelectedOptions3(selectedOptions3);
  };

  return (
    <div className="pdf">
      <Header />
      <div className="pdfBearbeiten">
        <div className="selectBox">
          <div className="label">Restbstand</div>
          <div className="titles">
            Menge, Artikel Nr, Netto, Vers. St., Brutto
          </div>
          <Select
            className="select"
            options={options}
            isMulti
            value={selectedOptions}
            onChange={handleSelectChange}
          />
        </div>

        <div className="selectBox">
          <div className="label">Verkaufsarten</div>
          <div className="titles">
            Menge, Zahlungs-Datum, Artikel Nr, Netto, Vers. St., Brutto, Gewinn,
            Verkaufspreis
          </div>
          <Select
            className="select"
            options={options}
            isMulti
            value={selectedOptions1}
            onChange={handleSelectChange1}
          />
        </div>

        <div className="selectBox">
          <div className="label">Storno</div>
          <div className="titles">
            Menge, Storno-Datum, Artikel Nr, Netto, Vers. St., Brutto, Gewinn,
            Verkaufspreis
          </div>
          <Select
            className="select"
            options={options}
            isMulti
            value={selectedOptions2}
            onChange={handleSelectChange2}
          />
        </div>

        <div className="selectBox">
          <div className="label">Verlust</div>
          <div className="titles">
            Menge, Verlust-Datum, Artikel Nr, Netto, Vers. St., Brutto, Gewinn,
            Verkaufspreis
          </div>
          <Select
            className="select"
            options={options}
            isMulti
            value={selectedOptions3}
            onChange={handleSelectChange3}
          />
        </div>
      </div>
    </div>
  );
};

export default PDF;
