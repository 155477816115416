import Axios from "axios";
import config from "./config";

const axios = Axios.create({
  baseURL: config.apiBaseUrl,
  timeout: config.responseTimeout,
  withCredentials: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default axios;
