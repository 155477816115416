import "../styles/Home.scss";
import Header from "../components/header";
import { useState, useEffect, useRef } from "react";
import Calendar from "color-calendar";
import axios from "../axios";
import { useContextProvider } from "../context/context";
import Edit from "../components/edit";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { German } from "flatpickr/dist/l10n/de.js";
import { generatePDFFile } from "../components/pdf";
import { generatePDF } from "../components/pdfBar";
import config from "../config";
import CsvFile from "../components/csv";

const Auswerten = () => {
  const {
    products,
    token,
    tokenType,
    paymentMethods,
    articles,
    setArticles,
    insurances,
    selectedDate,
    setSelectedDate,
    tableRows,
    settableRows,
    pdfBar,
    setPdfBar,
    Alle,
    setAlle,
    firstArticleDate,
    fetchFirstArticleDate,
    verifyToken,
  } = useContextProvider();

  const [edit, setEdit] = useState({ status: false, element: null });
  const [artikelBz, setArtikelBz] = useState("Alle");
  const [artikelNr, setArtikelNr] = useState("");
  const [artikelArt, setArtikelArt] = useState("");
  const [evbNr, setEvbNr] = useState("");
  const [verkaufsArt, setVerkaufsArt] = useState("Alle");

  const [showEditBox, setshowEditBox] = useState({ status: false, i: 0 });
  const [showDeleteBox, setShowDeleteBox] = useState({
    status: false,
    element: {},
  });
  const [loading, setLoading] = useState(false);
  const [stornoRetourChecked, setStornoRetourChecked] = useState(false);
  const [verlustChecked, setVerlustChecked] = useState(false);
  const [restBestandChecked, setRestBestandChecked] = useState(false);
  const [tablecolumn, setTableColumn] = useState([]);
  const [monate, setmonate] = useState(false);
  const [DailyArticles, setDailyArticles] = useState([]);
  const [DailyForm, setDailyForm] = useState(true);
  const [pageNum, setpageNum] = useState(1);
  const [pagesCunt, setPagesCount] = useState([]);
  const [pages, setPages] = useState(0);
  const [title, setTitle] = useState(false);
  const timeoutRef = useRef(null);
  const [calendarMode, setCalendarMode] = useState("single");
  const [totalNetto, setTotalNetto] = useState(0);
  const [totalSteuer, setTotalSteuer] = useState(0);
  const [totalBrutto, setTotalBrutto] = useState(0);
  const [totalGewinn, setTotalGewinn] = useState(0);
  const [totalMenge, setTotalMenge] = useState(0);
  const [totalVerkaufpreis, setTotalVerkaufpreis] = useState(0);

  const [filteredArticles, setFilteredArticles] = useState([]);

  useEffect(() => {
    verifyToken();
  }, []);
  useEffect(() => {
    let nettoSum = 0;
    let steuerSum = 0;
    let bruttoSum = 0;
    let gewinnSum = 0;
    let mengeSum = 0;
    let verkaufpreisSum = 0;

    articles.forEach((article) => {
      const gewinn = Number(article.selling_price)
        ? Number(article.selling_price) - Number(article.brutto)
        : 0;
      gewinnSum += gewinn > 0 ? Number(gewinn.toFixed(2)) : 0;
      nettoSum += Number(article.netto);
      steuerSum += Number(article.insurance_tax);
      bruttoSum += Number(article.brutto);
      verkaufpreisSum += Number(article.selling_price);
      mengeSum += 1;
    });
    setTotalNetto(nettoSum);
    setTotalSteuer(steuerSum);
    setTotalBrutto(bruttoSum);
    setTotalGewinn(gewinnSum);
    setTotalMenge(mengeSum);
    setTotalVerkaufpreis(verkaufpreisSum);
  }, [articles]);

  const handleScroll = () => {
    setTitle(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setTitle(false);
    }, 3000);
  };

  useEffect(() => {
    if (articles.length) {
      const numPages = Math.ceil(articles.length / 100);
      setPages(numPages);

      const articlesPerPage = 100;
      const startIndex = (pageNum - 1) * articlesPerPage;
      const endIndex = startIndex + articlesPerPage;
      const x = articles.filter((article, i) => {
        if (i >= startIndex && i < endIndex) {
          return article;
        }
      });
      setFilteredArticles(articles.slice(startIndex, endIndex));

      const pagesArray = [];
      for (let i = 1; i <= numPages; i++) {
        pagesArray.push(i);
      }
      setPagesCount(pagesArray);
    }
  }, [pageNum, articles]);

  useEffect(() => {
    fetchFirstArticleDate();
  }, [fetchFirstArticleDate]);

  useEffect(() => {
    const data = localStorage.getItem("Restbstand");
    const arry = JSON.parse(data);
    if (arry) {
      arry.forEach((element) => {
        tablecolumn.push(element.value);
      });
    }
  }, []);

  const handleStornoRetourChange = () => {
    setStornoRetourChecked(!stornoRetourChecked);
    setVerlustChecked(false);
    setRestBestandChecked(false);
  };

  const handleVerlustChange = () => {
    setStornoRetourChecked(false);
    setVerlustChecked(!verlustChecked);
    setRestBestandChecked(false);
  };

  const handleRestBestandChange = () => {
    setStornoRetourChecked(false);
    setVerlustChecked(false);
    setRestBestandChecked(!restBestandChecked);
  };

  const löchen = async (id) => {
    await verifyToken();

    const element = {
      id: id,
    };
    const jsonData = JSON.stringify(element);
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const res = await axios.delete("/articles", {
        headers: headers,
        data: jsonData,
      });
      console.log(res);

      if (res) {
        const editedData = articles.filter((article, i) => {
          if (article.id != id) {
            return article;
          }
        });

        setArticles(editedData);

        setShowDeleteBox({ status: false, element: {} });
        NotificationManager.success(
          `Element mit der ID: ${id} erfolgreich gelöscht`,
          "",
          3000
        );
      }
    } catch (error) {
      NotificationManager.error(error.message, error.code, 5000);
    }
  };

  function formatiereDatum(date) {
    const heute = date;
    const jahr = heute.getFullYear();
    let monat = heute.getMonth() + 1;
    if (monat < 10) {
      monat = "0" + monat;
    }
    let tag = heute.getDate();
    if (tag < 10) {
      tag = "0" + tag;
    }
    return `${jahr}-${monat}-${tag}`;
  }

  const getArticles = async () => {
    await verifyToken();
    setLoading(true);
    setArticles([]);
    setPagesCount([]);
    setpageNum(1);
    const insurance = insurances.filter((e) => {
      return e.number == artikelArt;
    });

    const compareDates = (dates) => {
      return dates.reduce((a, b) => {
        return a.getTime() === b.getTime() ? true : false;
      });
    };

    let obj = {};
    const x = artikelNr
      ? artikelNr.trim().split(" ").length == 2
        ? (obj = {
            start: artikelNr.split(" ")[0],
            end: artikelNr.split(" ")[1],
          })
        : artikelNr.trim()
      : null;

    let numberList = [];
    if (artikelNr.trim().split(" ").length == 2) {
      for (let i = Number(x.start); i <= Number(x.end); i++) {
        numberList.push(i);
      }
    }
    const article_numbers = numberList.join(",");

    let params = {
      import_date: Alle
        ? `${firstArticleDate},${formatiereDatum(new Date())}`
        : compareDates(selectedDate)
        ? formatiereDatum(selectedDate[0])
        : `${formatiereDatum(selectedDate[0])},${formatiereDatum(
            selectedDate[1]
          )}`,
      insurance_id: insurance[0] ? insurance[0]?.id : null,
      product_id: artikelBz === "Alle" ? null : Number(artikelBz),
      article_number: article_numbers
        ? article_numbers
        : x
        ? x
        : artikelNr.trim(),
      evb: evbNr ? evbNr : null,
      payment_method_id: verkaufsArt === "Alle" ? null : Number(verkaufsArt),
    };

    if (verkaufsArt != "Alle") {
      params = {
        sale_date: Alle
          ? null
          : compareDates(selectedDate)
          ? formatiereDatum(selectedDate[0])
          : `${formatiereDatum(selectedDate[0])},${formatiereDatum(
              selectedDate[1]
            )}`,
        insurance_id: insurance[0] ? insurance[0]?.id : null,
        product_id: artikelBz === "Alle" ? null : Number(artikelBz),
        article_number: article_numbers ? article_numbers : x,
        evb: evbNr ? evbNr : null,
        payment_method_id: verkaufsArt === "Alle" ? null : Number(verkaufsArt),
      };
    }

    if (stornoRetourChecked) {
      params = {
        cancellation_date: Alle
          ? `${firstArticleDate},${formatiereDatum(new Date())}`
          : compareDates(selectedDate)
          ? formatiereDatum(selectedDate[0])
          : `${formatiereDatum(selectedDate[0])},${formatiereDatum(
              selectedDate[1]
            )}`,
        insurance_id: insurance[0] ? insurance[0]?.id : null,
        product_id: artikelBz === "Alle" ? null : Number(artikelBz),
        article_number: article_numbers ? article_numbers : x,
        evb: evbNr ? evbNr : null,
        payment_method_id: verkaufsArt === "Alle" ? null : Number(verkaufsArt),
      };
    }

    if (verlustChecked) {
      params = {
        loss_date: Alle
          ? `${firstArticleDate},${formatiereDatum(new Date())}`
          : compareDates(selectedDate)
          ? formatiereDatum(selectedDate[0])
          : `${formatiereDatum(selectedDate[0])},${formatiereDatum(
              selectedDate[1]
            )}`,
        insurance_id: insurance[0] ? insurance[0]?.id : null,
        product_id: artikelBz === "Alle" ? null : Number(artikelBz),
        article_number: article_numbers ? article_numbers : x,
        evb: evbNr ? evbNr : null,
        payment_method_id: verkaufsArt === "Alle" ? null : Number(verkaufsArt),
      };
    }

    if (restBestandChecked) {
      params = {
        remaining_stock_date: `${formatiereDatum(selectedDate[0])}`,
      };
    }

    const filteredData = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== null)
    );

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const sortArticlesByDate = (articles) => {
      return articles.sort(
        (a, b) => new Date(a.import_date) - new Date(b.import_date)
      );
    };

    if (verkaufsArt != "Alle") {
      setPdfBar(true);
    } else {
      setPdfBar(false);
    }
    let url = "/articles";

    try {
      setLoading(true);

      while (url) {
        const { data } = await axios.get(url, {
          headers: headers,
          params: filteredData,
        });

        if (data.data.length) {
          setArticles((prevArticles) =>
            sortArticlesByDate([...prevArticles, ...data.data])
          );
        } else {
          NotificationManager.info("Keine Ergebnisse", "", 3000);
          break;
        }

        if (data.next_page_url) {
          url = data.next_page_url;
        } else {
          break;
        }
      }
    } catch (error) {
      console.log(error?.response?.data.message);
      NotificationManager.error(error?.response?.data.message, "", 5000);
    } finally {
      setLoading(false);
    }
  };

  const groupArticlesByDay = (articles) => {
    const groupedArticles = {};

    articles.forEach((article) => {
      if (article) {
        const date = new Date(article.payment_date);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const key = `${year}-${String(month).padStart(2, "0")}-${String(
          day
        ).padStart(2, "0")}`;

        if (!groupedArticles[key]) {
          groupedArticles[key] = [];
        }
        groupedArticles[key].push(article);
      }
    });

    return groupedArticles;
  };

  useEffect(() => {
    const groupedArticles = groupArticlesByDay(articles);

    const sortedKeys = Object.keys(groupedArticles).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    const dailyArticles = sortedKeys.map((key) => groupedArticles[key]);

    setDailyArticles(dailyArticles);
  }, [articles]);

  return (
    <div>
      <Header />

      <div
        className="auswertenmMinBox"
        onClick={() => {
          setmonate(false);
        }}
      >
        <div
          className="auswertenForm"
          style={{ height: DailyForm ? "auto" : "45px", padding: "5px 150px" }}
        >
          <div style={{ opacity: DailyForm ? 1 : 0 }}>
            <div className="felderBox Erhaltsdatum">
              <label className="label">
                {!stornoRetourChecked && !verlustChecked && !restBestandChecked
                  ? verkaufsArt != "Alle"
                    ? paymentMethods.filter((e) => e.id == verkaufsArt)[0]
                        .title + " _ Datum"
                    : "Datum"
                  : null}

                {stornoRetourChecked && "Storno/Retour Datum"}
                {verlustChecked && "Verlust Datum"}

                {restBestandChecked && "Restbestand Datum"}
              </label>

              {Alle ? (
                <input className="calendar" type="text" value="Alle" readOnly />
              ) : (
                <>
                  <Flatpickr
                    className="calendar"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      altInput: false,
                      altFormat: "F j, Y",
                      time_24hr: false,
                      mode: calendarMode,
                      time_24hr: true,
                      locale: German,
                    }}
                  />
                  <div
                    className="mode"
                    onClick={() =>
                      setCalendarMode((prevMode) =>
                        prevMode === "single" ? "range" : "single"
                      )
                    }
                  >
                    {calendarMode === "single" ? "Bereich" : "Einzel"}
                  </div>
                </>
              )}
            </div>
            <div className="felderBox Artikelbezeichnung">
              <label className="label">Artikelbezeichnung</label>
              <select onChange={(e) => setArtikelBz(e.target.value)}>
                <option value="Alle" key={"alle"} defaultChecked>
                  Alle
                </option>
                {products.map((product, i) => {
                  return (
                    <option value={product.id} key={product.id}>
                      {product.title}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="felderBox ArtikelArt">
              <label className="label">Artikel Art</label>
              <input
                type="text"
                onChange={(e) => setArtikelArt(e.target.value)}
              />
            </div>
            <div className="felderBox">
              <label className="label">Artikel Nr</label>
              <input
                type="text"
                onChange={(e) => setArtikelNr(e.target.value)}
              />
            </div>
            <div className="felderBox">
              <label className="label">eVB Nr.</label>
              <input type="text" onChange={(e) => setEvbNr(e.target.value)} />
            </div>
            <div className="felderBox">
              <label className="label">Verkaufs. Art</label>
              <select onChange={(e) => setVerkaufsArt(e.target.value)}>
                <option value="Alle" defaultChecked>
                  Alle
                </option>
                {paymentMethods.map((method, i) => {
                  return (
                    <option value={method.id} key={i}>
                      {method.title}
                      {method.auto_payment_date ? "✔️" : null}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="felderBox boxes">
              <div className="Box">
                <label className="label">Storno/Retour</label>
                <input
                  type="checkbox"
                  name="option"
                  checked={stornoRetourChecked}
                  onChange={handleStornoRetourChange}
                />
              </div>

              <div className="Box">
                <label className="label">Verlust</label>
                <input
                  type="checkbox"
                  name="option"
                  checked={verlustChecked}
                  onChange={handleVerlustChange}
                />
              </div>

              <div className="Box">
                <label className="label">Rest Bestand</label>
                <input
                  type="checkbox"
                  name="option"
                  checked={restBestandChecked}
                  onChange={handleRestBestandChange}
                />
              </div>
            </div>
          </div>
          <div style={{ transition: "all 0.5s", opacity: DailyForm ? 1 : 0 }}>
            {!Alle ? (
              <button className="AlleBtn" onClick={() => setAlle(!Alle)}>
                Alle
              </button>
            ) : (
              <button className="AlleBtn" onClick={() => setAlle(!Alle)}>
                kalender
              </button>
            )}
          </div>

          <i
            className={`fa-solid icon ${
              DailyForm ? "fa-chevron-down" : "fa-chevron-up"
            } `}
            onClick={() => setDailyForm(!DailyForm)}
          ></i>

          <div
            className="btnBox"
            style={
              DailyForm
                ? null
                : {
                    position: "absolute",
                    top: "8px",
                    left: " calc( 50% - 350px ) ",
                  }
            }
          >
            {!loading && articles.length > 0 ? (
              <div
                className="pdfBox"
                onClick={() => {
                  CsvFile(articles, products, paymentMethods, insurances);
                }}
              >
                CSV Generierung
                <i className="fa-solid fa-file-csv"></i>
              </div>
            ) : null}

            {!loading && articles.length > 0 && !pdfBar ? (
              <div
                className="pdfBox"
                style={
                  verlustChecked || stornoRetourChecked || restBestandChecked
                    ? {}
                    : { opacity: 0.6, cursor: "not-allowed" }
                }
                onClick={() => {
                  if (verlustChecked || stornoRetourChecked) {
                    if (stornoRetourChecked) {
                      generatePDF(articles, "storno");
                    }

                    if (verlustChecked) {
                      generatePDF(articles, "verlust");
                    }
                  }
                  if (restBestandChecked) {
                    generatePDFFile(articles);
                  }
                }}
              >
                PDF Generieren
                <i className="fa-regular fa-file-pdf"></i>
              </div>
            ) : null}

            {!loading && articles.length > 0 && pdfBar ? (
              <>
                <div
                  className="pdfBox"
                  onClick={(e) => {
                    e.stopPropagation();
                    setmonate(!monate);
                  }}
                >
                  PDF Generieren
                  <i className="fa-regular fa-file-pdf"></i>
                </div>
                {monate && (
                  <div className="monate">
                    {DailyArticles.map((e) => {
                      return (
                        <div
                          onClick={() => {
                            generatePDF(e, "Verkaufsarten");
                          }}
                        >
                          <i className="fa-regular fa-file-pdf"></i>

                          {e[0].payment_date
                            ? `${
                                e[0].payment_date?.split(" ")[0].split("-")[0]
                              }-${
                                e[0].payment_date?.split(" ")[0].split("-")[1]
                              }-${
                                e[0].payment_date?.split(" ")[0].split("-")[2]
                              }`
                            : "kein Zahlungs-Datum"}
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : null}

            <button onClick={getArticles}>
              {loading && (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}
              Senden
            </button>
          </div>
        </div>

        {articles.length > 0 ? (
          <div className="tableBox" style={DailyForm ? {} : { height: "92% " }}>
            {title && (
              <div className="title">{filteredArticles.length}/ Artikel</div>
            )}
            {loading && (
              <div className="loudingBox">
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            )}

            <div className="tableColumn">
              <div className="datum">Import-Datum</div>
              <div className="datum">Verkaufs-Art</div>
              <div className="datum">Verkaufs-Datum</div>
              <div className="datum">Zahlungs-Datum</div>
              <div className="datum">Storno/Retour-Datum</div>
              <div className="datum">Verlust-Datum</div>
              <div className="menge">Menge</div>
              <div className="arBz">Art. Bezeichnung</div>
              <div className="arAr">Artk.Art</div>
              <div className="arNr"> Art. Nr.</div>
              <div className="eVB">eVB</div>
              <div className="netto">Netto</div>
              <div className="st">Vers. St.</div>
              <div className="brutto">Brutto</div>
              <div className="gewinn">Gewinn</div>
              <div className="verkaufpreis">Verkaufpreis</div>
              <div className="edit"></div>
            </div>
            <div className="tableInfo" onScroll={handleScroll}>
              {filteredArticles.map((article, i) => {
                if (article) {
                  const gewinn = Number(article.selling_price)
                    ? Number(article.selling_price) - Number(article.brutto)
                    : 0;
                  const pyM = paymentMethods.filter((e) => {
                    return e.id == article.payment_method_id;
                  });
                  const aBz = products.filter((e) => {
                    return e.id == article.product_id;
                  });
                  const arNr = insurances.filter((e) => {
                    return e.id == article.insurance_id;
                  });

                  const row = tablecolumn.map((column) => {
                    if (column === "menge") {
                      return 1;
                    }
                    if (column === "product_title") {
                      return aBz[0]?.title;
                    }
                    if (column === "insurance_title") {
                      return arNr[0]?.number;
                    }
                    if (column === "payment_method") {
                      return pyM[0] ? pyM[0]?.title : "-";
                    }
                    if (column === "gewinn") {
                      return gewinn ? gewinn : "-";
                    }
                    return article[column];
                  });
                  tableRows.push(row);

                  return (
                    <div
                      className="tableRow"
                      key={i}
                      style={{
                        backgroundColor: Number(gewinn) < 0 ? "#f55050" : "",
                      }}
                    >
                      <div className="datum">
                        {article.import_date
                          ? article.import_date?.split(" ")[0]
                          : "-"}
                      </div>
                      <div className="datum">
                        {pyM[0] ? pyM[0]?.title : "-"}
                      </div>
                      <div className="datum">
                        {article.sale_date
                          ? article.sale_date?.split(" ")[0]
                          : "-"}
                      </div>
                      <div className="datum">
                        {article.payment_date
                          ? article.payment_date?.split(" ")[0]
                          : "-"}
                      </div>
                      <div className="datum">
                        {article.cancellation_date
                          ? article.cancellation_date.split(" ")[0]
                          : "-"}{" "}
                      </div>
                      <div className="datum">
                        {article.loss_date
                          ? article.loss_date.split(" ")[0]
                          : "-"}
                      </div>
                      <div className="menge">1</div>
                      <div className="arBz">{aBz[0]?.title}</div>
                      <div className="arAr">{arNr[0]?.number}</div>
                      <div className="arNr">
                        {article.number ? article.number : "-"}
                      </div>
                      <div className="eVB">
                        {article.evb ? article.evb : "-"}
                      </div>
                      <div className="netto">
                        {article.netto ? article.netto : "-"}
                      </div>
                      <div className="st">
                        {article.insurance_tax ? article.insurance_tax : "-"}
                      </div>
                      <div className="brutto">
                        {article.brutto ? article.brutto : "-"}
                      </div>
                      <div className="gewinn">{gewinn.toFixed(2)} </div>
                      <div className="verkaufpreis">
                        {article.selling_price ? article.selling_price : "-"}
                      </div>
                      <i
                        className="fa-solid fa-ellipsis-vertical edit "
                        onClick={() =>
                          setshowEditBox({
                            status: !showEditBox.status,
                            i: i,
                          })
                        }
                      ></i>
                      {showEditBox.status && showEditBox.i === i ? (
                        <div className="editBox">
                          <i
                            className="fa-solid fa-pen-to-square"
                            title="bearbeiten"
                            onClick={() => {
                              setEdit({ status: true, element: article });
                              setshowEditBox({ status: false, i: 0 });
                            }}
                          ></i>
                          <i
                            className="fa-solid fa-trash-can"
                            title="löchen"
                            onClick={() => {
                              setShowDeleteBox({
                                status: true,
                                element: article,
                              });
                              setshowEditBox({ status: false, i: 0 });
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
            <div className="calc">
              <div className="pages">
                {pageNum} / {pagesCunt.length} seiten
                <div className="Box">
                  <i
                    class="fa-solid fa-angle-left"
                    onClick={(e) => {
                      const element = document.getElementById("scroll");
                      element.scrollLeft -= 100;
                    }}
                  ></i>
                  <div id="scroll" className="numsBox">
                    {pagesCunt.map((num, i) => {
                      return (
                        <div
                          className="nums"
                          style={{
                            backgroundColor: pageNum == num ? "#189481" : "",
                          }}
                          onClick={() => {
                            setpageNum(num);
                          }}
                        >
                          {" "}
                          {num}{" "}
                        </div>
                      );
                    })}
                  </div>
                  <i
                    class="fa-solid fa-angle-right"
                    onClick={(e) => {
                      const element = document.getElementById("scroll");
                      element.scrollLeft += 100;
                    }}
                  ></i>
                </div>
              </div>
              <div className="infos">
                <div>
                  {" "}
                  <span>Menge:</span> {totalMenge}{" "}
                </div>
                <div>
                  {" "}
                  <span>Netto:</span> {totalNetto.toFixed(2)} €{" "}
                </div>
                <div>
                  {" "}
                  <span>Vers. St.:</span> {totalSteuer.toFixed(2)} €{" "}
                </div>
                <div>
                  {" "}
                  <span>Brutto:</span> {totalBrutto.toFixed(2)} €{" "}
                </div>
                <div>
                  {" "}
                  <span>Verkaufpreis:</span> {totalVerkaufpreis.toFixed(2)} €{" "}
                </div>
                <div>
                  {" "}
                  <span>Gewinn:</span> {totalGewinn.toFixed(2)} €{" "}
                </div>
              </div>
            </div>
            {showDeleteBox.status && (
              <div className="deleteBox">
                <div className="delete">
                  <div className="text">
                    {" "}
                    Sind Sie sicher, dass Sie Elemente nummer{" "}
                    {showDeleteBox.element.number} löschen möchten?{" "}
                  </div>
                  <div className="btnBox">
                    <button
                      className="abbrechen"
                      onClick={() =>
                        setShowDeleteBox({ status: false, element: {} })
                      }
                    >
                      Abbrechen
                    </button>
                    <button
                      className="löchen"
                      onClick={() => löchen(showDeleteBox.element.id)}
                    >
                      Löchen
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      {edit.status ? (
        <Edit
          element={edit.element}
          setEdit={setEdit}
          getArticles={getArticles}
        />
      ) : null}
    </div>
  );
};

export default Auswerten;
