import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "../axios";
import { useNavigate } from "react-router-dom";

const Context = createContext();
export const ContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [articles, setArticles] = useState([]);
  const [token, setToken] = useState(null);
  const [tokenType, setTokenType] = useState(null);
  const [isLogedIn, setIsLogedIn] = useState(null);
  const [selectedDate, setSelectedDate] = useState([new Date()]);
  const [tableRows, settableRows] = useState([]);
  const [pdfBar, setPdfBar] = useState(false);
  const [Alle, setAlle] = useState(true);
  const [firstArticleDate, setFirstArticleDate] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLogedIn) {
      window.addEventListener("unload", () => {
        navigate(0);
      });
    }
  }, []);

  useEffect(() => {
    const tok = localStorage.getItem("token");
    const tokT = localStorage.getItem("token_type");
    if (tok && tokT) {
      setToken(tok);
      setTokenType(tokT);
      setIsLogedIn(true);
    }
  }, [isLogedIn]);

  useEffect(() => {
    if (isLogedIn && token && tokenType) {
      verifyToken();
      getProducts();
      getPaymentMethods();
      getInsurances();
      fetchFirstArticleDate();
    }
  }, [token, tokenType, isLogedIn]);

  const fetchFirstArticleDate = async () => {
    if (token && tokenType) {
      const headers = {
        Authorization: `${tokenType} ${token}`,
      };

      try {
        const response = await axios.get(
          "/articles?limit=1&order_by=import_date",
          { headers }
        );
        if (response.data && response.data.length > 0) {
          const date = new Date(response.data[0].import_date);
          const formattedDate = date.toISOString().split("T")[0];
          setFirstArticleDate(formattedDate);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen des ersten Artikeldatums:", error);
      }
    }
  };

  const verifyToken = async () => {
    const token = localStorage.getItem("token");
    const tokenType = localStorage.getItem("token_type");

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      if (token && tokenType) {
        await axios.post(
          "/tokens/verify",
          { Token: token },
          { headers: headers }
        );
      } else {
        localStorage.setItem("token", "");
        localStorage.setItem("token_type", "");
        navigate("/login");
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.setItem("token", "");
        localStorage.setItem("token_type", "");
        navigate("/login");
      }
    }
  };

  const getPaymentMethods = async () => {
    if (token && tokenType) {
      const headers = {
        Authorization: `${tokenType} ${token}`,
      };

      try {
        await axios
          .get("/payment-methods", { headers: headers })
          .then((res) => {
            const array = res.data.data.sort(function (a, b) {
              var titleA = a.title.toUpperCase();
              var titleB = b.title.toUpperCase();
              if (titleA < titleB) {
                return -1;
              }
              if (titleA > titleB) {
                return 1;
              }
              return 0;
            });
            setPaymentMethods(array);
          });
      } catch (error) {
        if (error.response.status === 401) {
        }
      }
    }
  };

  const getProducts = async () => {
    if (token && tokenType) {
      const headers = {
        Authorization: `${tokenType} ${token}`,
      };

      try {
        await axios.get("/products", { headers: headers }).then((res) => {
          const array = res.data.data.sort(function (a, b) {
            var titleA = a.title.toUpperCase();
            var titleB = b.title.toUpperCase();
            if (titleA < titleB) {
              return -1;
            }
            if (titleA > titleB) {
              return 1;
            }
            return 0;
          });
          setProducts(array);
        });
      } catch (error) {
        if (error?.response?.status === 401) {
        }
      }
    }
  };

  const getInsurances = async () => {
    let url = "/insurances";
    let params = {};

    if (!token || !tokenType) {
      console.warn("Token oder TokenType fehlt");
      return;
    }

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      while (url) {
        const { data } = await axios.get(url, { headers, params });

        if (data.data.length) {
          setInsurances((prevInsurances) => [...prevInsurances, ...data.data]);
        } else {
          break;
        }

        if (data.next_page_url) {
          const { searchParams } = new URL(data.next_page_url);
          params = Object.fromEntries(searchParams);
        } else {
          url = null;
        }
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Versicherungen:", error);
      if (error.response && error.response.status === 401) {
        console.error("Nicht autorisiert. Bitte erneut anmelden.");
        // Here you can add additional actions for 401 errors
      }
    }
  };

  return (
    <Context.Provider
      value={{
        products,
        setIsLogedIn,
        insurances,
        token,
        tokenType,
        paymentMethods,
        setPaymentMethods,
        articles,
        setArticles,
        getPaymentMethods,
        getProducts,
        getInsurances,
        verifyToken,
        selectedDate,
        setSelectedDate,
        tableRows,
        settableRows,
        pdfBar,
        setPdfBar,
        Alle,
        setAlle,
        setToken,
        setTokenType,
        firstArticleDate,
        fetchFirstArticleDate,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useContextProvider = () => {
  return useContext(Context);
};
