import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDF = (data, ststus) => {
  // Lese die Spaltenüberschriften aus dem localStorage
  let spalten = [];

  if (ststus == "storno") {
    spalten = localStorage.getItem("Storno");
  }
  if (ststus == "verlust") {
    spalten = localStorage.getItem("Verlust");
  }

  if (ststus == "Verkaufsarten") {
    spalten = localStorage.getItem("Verkaufsarten");
  }

  let tableColumns = [];
  const arry = JSON.parse(spalten);
  if (arry) {
    arry.forEach((element) => {
      tableColumns.push(element.label);
    });
  }

  if (tableColumns.length == 0) {
    alert(
      "Es gibt keine Tabellenspalten. Bitte füge auf der Bearbeitungsseite Tabellenspalten hinzu."
    );
    return;
  }

  let totalNetto = 0;
  let totalInsuranceTax = 0;
  let totalBrutto = 0;
  let totalGewinn = 0;
  let totalSellingPrice = 0;
  let totalMange = 0;

  const tableRows = data.map((item) => {
    const mange = 1;
    const netto = item.netto ? parseFloat(item.netto) : 0;
    const insuranceTax = item.insurance_tax
      ? parseFloat(item.insurance_tax)
      : 0;
    const brutto = item.brutto ? parseFloat(item.brutto) : 0;
    const sellingPrice = item.selling_price
      ? parseFloat(item.selling_price)
      : 0;
    const gewinn =
      sellingPrice && netto ? Math.max(sellingPrice - netto, 0) : 0;

    totalMange += 1;
    totalNetto += netto;
    totalInsuranceTax += insuranceTax;
    totalBrutto += brutto;
    totalGewinn += gewinn;
    totalSellingPrice += sellingPrice;

    const paymentDate = item.payment_date
      ? item.payment_date.split(" ")[0]
      : "";
    const cancellationDate = item.cancellation_date
      ? item.cancellation_date.split(" ")[0]
      : "";
    const lossDate = item.loss_date ? item.loss_date.split(" ")[0] : "";

    return [
      mange,
      ststus == "storno"
        ? cancellationDate
        : ststus == "verlust"
        ? lossDate
        : ststus == "Verkaufsarten"
        ? paymentDate
        : null,
      item.number,
      netto.toFixed(2),
      insuranceTax.toFixed(2),
      brutto.toFixed(2),
      gewinn.toFixed(2),
      sellingPrice.toFixed(2),
    ];
  });

  const summaryRow = [
    totalMange,
    "",
    "",
    totalNetto.toFixed(2) + " €",
    totalInsuranceTax.toFixed(2) + " €",
    totalBrutto.toFixed(2) + " €",
    totalGewinn.toFixed(2) + " €",
    totalSellingPrice.toFixed(2) + " €",
  ];
  tableRows.push(summaryRow);

  const doc = new jsPDF();

  const styles = {
    font: "times",
    fontStyle: "normal",
    fontSize: 12,
    textColor: [0, 0, 0],
    lineWidth: 0.1,
    lineColor: [0, 0, 0],
    fillStyle: "DF",
  };

  const headStyles = {
    fillColor: [255, 255, 255],
    textColor: [0, 0, 0],
  };

  const bodyStyles = {
    fillColor: [255, 255, 255],
    textColor: [0, 0, 0],
  };

  const tableStartY = 5;

  doc.autoTable({
    head: [tableColumns],
    body: tableRows,
    didDrawPage: function (data) {
      // Beispiel für benutzerdefinierte Zeichnungen auf der Seite
      doc.setFont("times", "normal");
      doc.setFontSize(10);

      // Positioniere den Table so, dass er den Rand der Seite erreicht
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const tableBottom = data.table.finalY + pageHeight - tableStartY;

      // Datum im Fußbereich hinzufügen
      const date = new Date().toLocaleDateString();
      const footerText = `Datum: ${date}`;
      const textWidth = doc.getTextWidth(footerText);
      const textX = (pageWidth - textWidth) / 2;
      const textY = pageHeight - 10;
      doc.text(footerText, textX, textY);

      if (tableBottom >= pageHeight - 20) {
        doc.addPage();
      }
    },
    startY: tableStartY, // Startposition des Tables auf der Seite
    styles: styles,
    headStyles: headStyles,
    bodyStyles: bodyStyles,
  });

  const blob = new Blob([doc.output("blob")], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  window.open(url, "_blank");
};

export { generatePDF };
