import "../styles/Home.scss";
import Header from "../components/header";
import { useState, useEffect, useRef } from "react";
import Calendar from "color-calendar";
import { useContextProvider } from "../context/context";
import axios from "../axios";
import "../styles/verkauf.scss";
import swal from "sweetalert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { motion } from "framer-motion";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { German } from "flatpickr/dist/l10n/de.js";

const Verkauf = () => {
  const {
    insurances,
    products,
    paymentMethods,
    getPaymentMethods,
    verifyToken,
  } = useContextProvider();

  const selectRef = useRef(null);
  const paymentMethodsRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarValue, setCalendarValue] = useState("");
  const [StartArtNr, setStartArtNr] = useState(0);
  const [EndArtNr, setEndArtNr] = useState(0);
  const [gleich, setGleich] = useState(0);
  const [loud, setLoud] = useState(false);
  const [verkaufsDatum, setVerkaufsDatum] = useState(new Date());
  const [ArtikelArt, setArtikelArt] = useState("");
  const [Verkaufspreis, setVerkaufspreis] = useState("");

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    if (Verkaufspreis) {
      setVerkaufspreis(Verkaufspreis.replace(/,/g, "."));
    }
  }, [Verkaufspreis]);

  useEffect(() => {
    if ((StartArtNr, EndArtNr)) {
      const num = Number(EndArtNr) - Number(StartArtNr) + 1;
      setGleich(num);
    } else {
      setGleich(0);
    }
  }, [StartArtNr, EndArtNr]);

  useEffect(() => {
    const currentDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[currentDate.getMonth()];
    const day = currentDate.getDate().toString();
    const year = currentDate.getFullYear().toString();
    const formattedDate = [month, day, year];
    setCalendarValue(formattedDate);
  }, []);

  useEffect(() => {
    if (showCalendar) {
      const cal = new Calendar({
        id: "#calendar",
        theme: "basic",
        weekdayType: "long-upper",
        monthDisplayType: "long",
        calendarSize: "small",
        layoutModifiers: ["month-left-align"],
        primaryColor: "#3A576D",
        dateChanged: (currentDate, events) => {
          const data = currentDate.toString();
          const date = `${data.substr(4, 3)}-${data.substr(8, 2)}-${data.substr(
            11,
            4
          )}`;
          setCalendarValue(date.split("-"));
        },
      });
    }
  }, [showCalendar]);

  const Verkaufen = async () => {
    await verifyToken();

    setLoud(true);
    let number = "";
    for (let i = Number(StartArtNr); i <= Number(EndArtNr); i++) {
      number += `${i},`;
    }
    const array = [];
    for (let index = 0; index < number.length; index += 200) {
      array.push(number.substring(index, index + 200));
    }

    const insurance = insurances.filter((e) => {
      if (e.number == ArtikelArt) {
        return e;
      } else {
        return null;
      }
    });

    if (insurance.length == 0) {
      NotificationManager.warning("Artikel Art nicht gefunden", "", 3000);
      return;
    }

    const token = localStorage.getItem("token");
    const tokenType = localStorage.getItem("token_type");
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${tokenType} ${token}`,
    };

    try {
      let status = null;
      for (const chunk of array) {
        const data =
          JSON.parse(paymentMethodsRef.current.value).auto_payment_date == 0
            ? {
                product_id: Number(selectRef.current.value),
                insurance_id: insurance[0] && insurance[0].id,
                number: chunk.replace(/^,|,$/g, ""),
                number_count: chunk.replace(/^,|,$/g, "").split(",").length,
                payment_method_id: JSON.parse(paymentMethodsRef.current.value)
                  .id,
                selling_price: Number(Verkaufspreis).toFixed(2),
                sale_date: formatiereDatum(verkaufsDatum),
              }
            : {
                product_id: Number(selectRef.current.value),
                insurance_id: insurance[0] && insurance[0].id,
                number: chunk.replace(/^,|,$/g, ""),
                number_count: chunk.replace(/^,|,$/g, "").split(",").length,
                payment_method_id: JSON.parse(paymentMethodsRef.current.value)
                  .id,
                selling_price: Number(Verkaufspreis).toFixed(2),
                sale_date: formatiereDatum(verkaufsDatum),
                payment_date: formatiereDatum(verkaufsDatum),
              };

        const jsonData = JSON.stringify(data);

        const res = await axios.patch("/articles", jsonData, {
          headers: headers,
        });
        if (res) {
          status = true;
        }
      }

      if (status) {
        swal(
          `${gleich} Tikets mit Artikel Art:(${ArtikelArt}) von:${StartArtNr}
      bis:${EndArtNr} Erfolgreich Verkauft`,
          "",
          "success"
        );
      }
    } catch (error) {
      const msg = error.request.responseText.split(",")[0].split(":")[1];

      if (msg.includes("For at least one of the combinations of number")) {
        NotificationManager.warning("Artikel nicht gefunden", "", 3000);
      } else {
        NotificationManager.error(msg, error.code, 5000);
      }
    } finally {
      setLoud(false);
    }
  };

  function formatiereDatum(date) {
    const heute = date;
    const jahr = heute.getFullYear();
    let monat = heute.getMonth() + 1;
    if (monat < 10) {
      monat = "0" + monat;
    }
    let tag = heute.getDate();
    if (tag < 10) {
      tag = "0" + tag;
    }
    return `${jahr}-${monat}-${tag}`;
  }

  return (
    <div
      onClick={(e) => {
        setShowCalendar(false);
      }}
    >
      <Header />

      <div className="mainbox">
        <div className="verkaufForm">
          <div className="felderBox Erhaltsdatum">
            <label className="label">Verkaufs Datum</label>
            <Flatpickr
              className="calendar"
              value={verkaufsDatum}
              onChange={(date) => setVerkaufsDatum(date[0])}
              options={{
                enableTime: false,
                dateFormat: "Y-m-d",
                altInput: false,
                altFormat: "F j, Y",
                time_24hr: false,
                locale: German,
              }}
            />
          </div>
          <div className="felderBox Artikelbezeichnung">
            <label className="label">Artikel Bezeichnung</label>
            <select ref={selectRef} defaultValue={products[0]?.id}>
              {products.map((product) => (
                <option value={product.id} key={product.id}>
                  {product.title}
                </option>
              ))}
            </select>
          </div>
          <div className="felderBox ArtikelArt">
            <label className="label">Artikel Art</label>
            <input
              type="text"
              value={ArtikelArt}
              onChange={(e) => setArtikelArt(e.target.value)}
            />
          </div>
          <div className="felderBox Start">
            <label className="label">Artikel Nr - von:</label>
            <div className="cont">
              <input
                type="text"
                value={StartArtNr}
                onChange={(e) => setStartArtNr(e.target.value)}
              />
              <label>bis:</label>
              <input
                type="text"
                value={EndArtNr}
                onChange={(e) => setEndArtNr(e.target.value)}
              />
              <label>gleich:</label>
              <input type="text" value={gleich} onChange={() => {}} />
            </div>
          </div>
          <div className="felderBox">
            <label className="label">Verkaufspreis</label>
            <input
              type="text"
              value={Verkaufspreis}
              onChange={(e) => setVerkaufspreis(e.target.value)}
            />
          </div>

          <div className="felderBox">
            <label className="label">Verkaufs. Art</label>
            <select
              ref={paymentMethodsRef}
              defaultValue={JSON.stringify(paymentMethods[0])}
            >
              {paymentMethods.map((method, i) => (
                <option value={JSON.stringify(method)} key={method.id}>
                  {method.title} {method.auto_payment_date ? "✔️" : null}
                </option>
              ))}
            </select>
          </div>

          <div className="btnBox ">
            <button className="Eintragen" onClick={Verkaufen}>
              {loud ? <i class="fa-solid fa-circle-notch"></i> : "Verkaufen"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verkauf;
