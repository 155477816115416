import Header from "../components/header";
import { useState, useEffect, useRef } from "react";
import Calendar from "color-calendar";
import { useContextProvider } from "../context/context";
import "../styles/bearbeiten.scss";
import axios from "../axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { German } from "flatpickr/dist/l10n/de.js";
import config from "../config";

const Bearbeiten = () => {
  const {
    products,
    paymentMethods,
    token,
    tokenType,
    getPaymentMethods,
    getProducts,
    insurances,
    verifyToken,
  } = useContextProvider();

  const ArtikelbezeichnungRef = useRef();
  const VerkaufsArtRef = useRef();

  const [Methods, setpaymentMethods] = useState([]);
  const [addArtikelBzText, setAddArtikelBzText] = useState("");
  const [addVerkaufsArtinputText, setaddVerkaufsArtinputText] = useState("");
  const [mitZahlungsDatum, setMitZahlungsDatum] = useState(false);
  const [StartArtNr, setStartArtNr] = useState(0);
  const [EndArtNr, setEndArtNr] = useState(0);
  const [gleich, setGleich] = useState(0);
  const [inpurtText, setInpurtText] = useState("");
  const [inpurtTextMethods, setInpurtTextMethods] = useState("");
  const [ArtikelArt, setArtikelArt] = useState("");
  const [eVB, seteVB] = useState("");
  const [Netto, setNetto] = useState("");
  const [Steuer, setSteuer] = useState("");
  const [Brutto, setBrutto] = useState("");
  const [Verkaufspreis, setVerkaufspreis] = useState("");
  const [VerkaufDatum, setVerkaufDatum] = useState("");
  const [ZahlungsDatum, setZahlungsDatum] = useState("");
  const [StornoRetourDatum, setStornoRetourDatum] = useState("");
  const [VerlustDatum, setVerlustDatum] = useState("");
  const [editBtn, setEditBtn] = useState(false);
  const [änderungLoud, setÄnderungLoud] = useState(false);
  const [resetLoud, setResetLoud] = useState(false);
  const [deleteLoud, setDeleteLoud] = useState(false);

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    if (Netto) {
      setNetto(Netto.replace(/,/g, "."));
    }

    if (Steuer) {
      setSteuer(Steuer.replace(/,/g, "."));
    }
    if (Brutto) {
      setBrutto(Brutto.replace(/,/g, "."));
    }
    if (Verkaufspreis) {
      setVerkaufspreis(Verkaufspreis.replace(/,/g, "."));
    }
  }, [Brutto, Steuer, Netto, Verkaufspreis]);

  useEffect(() => {
    if (paymentMethods) {
      setpaymentMethods(paymentMethods);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if ((StartArtNr, EndArtNr)) {
      const num = Number(EndArtNr) - Number(StartArtNr) + 1;
      setGleich(num);
    } else {
      setGleich(0);
    }
  }, [StartArtNr, EndArtNr]);

  const addArtikelBz = async () => {
    if (!addArtikelBzText) {
      NotificationManager.error("Artikelbezeichnung ist pflichtfeld", "", 5000);
      return;
    }
    const data = {
      title: addArtikelBzText,
    };
    const jsonData = JSON.stringify(data);
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const res = await axios.post("/products", jsonData, { headers: headers });
      if (res) {
        setAddArtikelBzText("");
        getProducts();
        NotificationManager.success(
          "Neuen Artikel Bezeichnung erfolgreich hinzugefügt",
          "",
          3000
        );
      }
    } catch (error) {
      const msg = error.request.responseText.split(",")[0].split(":")[1];
      NotificationManager.error(msg, error.code, 5000);
    }
  };

  const editArtikelBz = async () => {
    const data = {
      title: inpurtText.title,
    };
    const jsonData = JSON.stringify(data);
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const res = await axios.patch(`/products/${inpurtText.id}`, jsonData, {
        headers: headers,
      });
      if (res) {
        setInpurtText({ title: "", id: null });
        getProducts();
        NotificationManager.success(
          " Artikel Bezeichnung erfolgreich geändert",
          "",
          3000
        );
      }
    } catch (error) {
      const msg = error.request.responseText.split(",")[0].split(":")[1];
      NotificationManager.error(msg, error.code, 5000);
    }
  };

  const addVerkaufsArt = async () => {
    if (!addArtikelBzText) {
      NotificationManager.error("VerkaufsArt ist pflichtfeld", "", 5000);
      return;
    }
    const data = {
      title: addVerkaufsArtinputText,
    };
    const jsonData = JSON.stringify(data);
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const res = await axios.post("/payment-methods", jsonData, {
        headers: headers,
      });
      if (res) {
        setaddVerkaufsArtinputText("");
        getPaymentMethods();
        NotificationManager.success(
          "Neuen Verkaufs Art erfolgreich hinzugefügt",
          "",
          3000
        );
      }
    } catch (error) {
      const msg = error.request.responseText.split(",")[0].split(":")[1];
      NotificationManager.error(msg, error.code, 5000);
    }
  };

  const editVerkausaArt = async () => {
    const data = {
      title: inpurtTextMethods.title,
      auto_payment_date: mitZahlungsDatum,
    };
    const jsonData = JSON.stringify(data);
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const res = await axios.patch(
        `/payment-methods/${inpurtTextMethods.id}`,
        jsonData,
        { headers: headers }
      );
      if (res) {
        setInpurtTextMethods({ title: "", id: null });
        getPaymentMethods();
        NotificationManager.success(
          "Artikel Bezeichnung erfolgreich geändert",
          "",
          3000
        );
      }
    } catch (error) {
      const msg = error.request.responseText.split(",")[0].split(":")[1];
      NotificationManager.error(msg, error.code, 5000);
    }
  };

  const löschen = async () => {
    await verifyToken();
    setDeleteLoud(true);

    if (!ArtikelArt && !gleich) {
      NotificationManager.info("Keine Tickets gefunden", "", 3000);
      setDeleteLoud(false);
      return;
    }

    let number = "";
    for (let i = Number(StartArtNr); i <= Number(EndArtNr); i++) {
      number += `${i},`;
    }

    const insurancesID = insurances.filter((e) => e.number == ArtikelArt);

    const array = [];
    for (let index = 0; index < number.length; index += 200) {
      array.push(number.substring(index, index + 200));
    }

    const token = localStorage.getItem("token");
    const tokenType = localStorage.getItem("token_type");
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      let status = null;
      if (ArtikelArt && gleich) {
        let data;
        for (const chunk of array) {
          data = {
            article_number: chunk.replace(/^,|,$/g, ""),
            insurance_id: insurancesID.length > 0 ? insurancesID[0].id : null,
            number_count: gleich ? Number(gleich) : null,
          };

          const res = await axios.get("/articles", {
            params: data,
            headers: headers,
          });

          if (res.data.data.length === 0) {
            NotificationManager.info("Keine Tickets gefunden", "", 3000);
            return;
          }

          const resArray = res.data.data.map((item) => item.id).join(",");
          if (resArray.length > 0) {
            const deleteRes = await axios.delete("/articles", {
              data: { id: resArray },
              headers: headers,
            });

            if (deleteRes) {
              status = true;
            }
          }
        }
      }
      if (status) {
        NotificationManager.success(
          `${gleich} Tickets wurden erfolgreich gelöscht`,
          "",
          3000
        );
      }
    } catch (error) {
      const msg = error.request.responseText.split(",")[0].split(":")[1];
      console.error(error);
      NotificationManager.error(msg, error.code, 5000);
    } finally {
      setDeleteLoud(false);
    }
  };

  const reset = async () => {
    await verifyToken();

    setResetLoud(true);

    if (!ArtikelArt && !gleich) {
      NotificationManager.info("Keine Tickets gefunden", "", 3000);
      setResetLoud(false);
      return;
    }

    if (isNaN(Number(StartArtNr)) || isNaN(Number(EndArtNr))) {
      NotificationManager.error(
        "Ungültige Start- oder Endartikelnummer",
        "",
        5000
      );
      console.error("Ungültige Start- oder Endartikelnummer");
      setResetLoud(false);
      return;
    }

    let numberList = [];
    for (let i = Number(StartArtNr); i <= Number(EndArtNr); i++) {
      numberList.push(i);
    }
    const article_numbers = numberList.join(",");

    const array = [];
    for (let index = 0; index <= article_numbers.length; index += 200) {
      array.push(article_numbers.substring(index, index + 200));
    }

    if (!ArtikelArt) {
      NotificationManager.error("ArtikelArt ist nicht definiert", "", 5000);
      console.error("ArtikelArt ist nicht definiert");
      setResetLoud(false);
      return;
    }

    const insurancesID = insurances.filter((e) => e.number === ArtikelArt);

    const token = localStorage.getItem("token");
    const tokenType = localStorage.getItem("token_type");
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      let status = null;

      for (const chunk of array) {
        const data = {
          article_number: chunk.replace(/^,|,$/g, ""),
          insurance_id:
            insurancesID.length > 0 ? insurancesID[0].id : undefined,
        };

        const res = await axios.get("/articles", {
          params: data,
          headers: headers,
        });

        if (res.data.data.length === 0) {
          NotificationManager.info("Keine Tickets gefunden", "", 3000);
          continue;
        }

        const resArray = res.data.data.map((item, i) => item.id).join(",");
        const numbers = res.data.data.map((item, i) => item.number).join(",");

        const data1 = {
          id: resArray,
          number: numbers,
          number_count: resArray.split(",").length,
          loss_date: null,
          cancellation_date: null,
          payment_date: null,
          sale_date: null,
          selling_price: null,
          payment_method_id: null,
        };

        const jsonData = JSON.stringify(data1);

        if (resArray.length) {
          const resData = await axios.patch("/articles", jsonData, {
            headers: headers,
          });
          if (resData.data) {
            status = true;
          }
        }
      }
      if (status) {
        NotificationManager.success(
          `${gleich} Tickets wurden erfolgreich zurückgesetzt`,
          "",
          3000
        );
      }
    } catch (error) {
      console.error(error);
      const msg = error.request.responseText.split(",")[0].split(":")[1];
      NotificationManager.error(msg, error.code, 5000);
    } finally {
      setResetLoud(false);
    }
  };

  const Änderung = async () => {
    await verifyToken();

    setÄnderungLoud(true);

    if (!ArtikelArt && !gleich) {
      NotificationManager.info("Keine Tickets gefunden", "", 3000);
    }
    if (isNaN(Number(StartArtNr)) || isNaN(Number(EndArtNr))) {
      NotificationManager.error(
        "Ungültige Start- oder Endartikelnummer",
        "",
        5000
      );
      setÄnderungLoud(false);

      console.error("Ungültige Start- oder Endartikelnummer");
      return;
    }

    let numberList = [];
    for (let i = Number(StartArtNr); i <= Number(EndArtNr); i++) {
      numberList.push(i);
    }
    const article_numbers = numberList.join(",");

    const array = [];
    for (let index = 0; index <= article_numbers.length; index += 200) {
      array.push(article_numbers.substring(index, index + 200));
    }

    if (!ArtikelArt) {
      NotificationManager.error("ArtikelArt ist nicht definiert", "", 5000);
      console.error("ArtikelArt ist nicht definiert");
      setÄnderungLoud(false);

      return;
    }

    const insurancesID = insurances.filter((e) => e.number === ArtikelArt);
    const token = localStorage.getItem("token");
    const tokenType = localStorage.getItem("token_type");
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${tokenType} ${token}`,
    };

    try {
      let status = null;
      for (const chunk of array) {
        const data1 = {
          article_number: chunk.replace(/^,|,$/g, ""),
          insurance_id:
            insurancesID.length > 0 ? insurancesID[0].id : undefined,
          number_count: gleich ? Number(gleich) : undefined,
        };
        const res1 = await axios.get("/articles", {
          params: data1,
          headers: headers,
        });
        if (res1.data.data.length == 0) {
          NotificationManager.info("Keine Tickets gefunden", "", 3000);
          setÄnderungLoud(false);
        }

        const resArray = res1.data.data.map((item) => item.id).join(",");
        const numbers = res1.data.data.map((item, i) => item.number).join(",");

        const data = {
          id: resArray,
          product_id:
            ArtikelbezeichnungRef.current.value != "Alle"
              ? Number(ArtikelbezeichnungRef.current.value)
              : res1.data.data[0].product_id,
          number_count: resArray.split(",").length,
          loss_date: VerlustDatum
            ? convertDate(VerlustDatum)
            : res1.data.data[0].loss_date?.split(" ")[0],
          cancellation_date: StornoRetourDatum
            ? convertDate(StornoRetourDatum)
            : res1.data.data[0].cancellation_date?.split(" ")[0],
          payment_date: ZahlungsDatum
            ? convertDate(ZahlungsDatum)
            : res1.data.data[0].payment_date?.split(" ")[0],
          sale_date: VerkaufDatum
            ? convertDate(VerkaufDatum)
            : res1.data.data[0].sale_date?.split(" ")[0],
          selling_price: Verkaufspreis
            ? `${Number(Verkaufspreis).toFixed(2)}`
            : res1.data.data[0].selling_price?.split(" ")[0],
          payment_method_id:
            VerkaufsArtRef.current.value != "Alle"
              ? Number(VerkaufsArtRef.current.value)
              : res1.data.data[0].payment_method_id,
          number: numbers ? numbers : null,
          evb: eVB ? eVB : res1.data.data[0].evb,
          netto: Netto
            ? `${Number(Netto).toFixed(2)}`
            : res1.data.data[0].netto,
          insurance_tax: Steuer
            ? `${Number(Steuer).toFixed(2)}`
            : res1.data.data[0].insurance_tax,
          brutto: Brutto
            ? `${Number(Brutto).toFixed(2)}`
            : res1.data.data[0].brutto,
        };
        const jsonData = JSON.stringify(data);
        const res = await axios.patch("/articles", jsonData, {
          headers: headers,
        });

        if (res.data) {
          status = true;
        }
      }

      if (status) {
        NotificationManager.success(
          `${gleich} Tickets wurden erfolgreich geändert`,
          "",
          3000
        );
      }
    } catch (error) {
      const msg = error?.request?.responseText?.split(",")[0].split(":")[1];
      NotificationManager.error(msg, error.code, 5000);
    } finally {
      setÄnderungLoud(false);
    }
  };

  function convertDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <Header />

      <div className=" einarbeiten">
        <div className="bearbeitenForm">
          <div className="row">
            <label className="label">Artikel Bezeichnung</label>
            <div className="productSelect">
              {products.map((product, i) => {
                return (
                  <div
                    className="option"
                    value={product.title}
                    key={i}
                    onDoubleClick={() =>
                      setInpurtText({ title: product.title, id: product.id })
                    }
                  >
                    {product.id === inpurtText.id ? (
                      <div className="inputBox">
                        <input
                          className="inputText"
                          value={inpurtText.title}
                          type="text"
                          onChange={(e) =>
                            setInpurtText({
                              title: e.target.value,
                              id: product.id,
                            })
                          }
                        />
                        <button
                          className="abbrechen"
                          onClick={() => setInpurtText({ title: "", id: null })}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                        <button className="speichern" onClick={editArtikelBz}>
                          <i className="fa-solid fa-check"></i>
                        </button>
                      </div>
                    ) : (
                      <div className="text">{product.title}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="addArtikelBzBox">
            <input
              type="text"
              value={addArtikelBzText}
              placeholder="Neuen Artikel Bezeichnung hinzufügen"
              onChange={(e) => setAddArtikelBzText(e.target.value)}
            />
            <button onClick={addArtikelBz}> Add </button>
          </div>

          <div className="row">
            <label className="label">Verkaufs. Art</label>
            <div className="productSelect">
              {Methods.map((Method, i) => {
                return (
                  <div
                    className="option"
                    value={Method.title}
                    key={i}
                    onDoubleClick={() => {
                      setMitZahlungsDatum(
                        Method.auto_payment_date == 1 ? true : false
                      );
                      setInpurtTextMethods({
                        title: Method.title,
                        id: Method.id,
                      });
                    }}
                  >
                    {Method.id === inpurtTextMethods.id ? (
                      <div className="inputBox">
                        <input
                          className="inputText"
                          value={inpurtTextMethods.title}
                          type="text"
                          onChange={(e) =>
                            setInpurtTextMethods({
                              title: e.target.value,
                              id: Method.id,
                            })
                          }
                        />

                        <label>VD&ZD</label>
                        <input
                          className="inputRadio"
                          type="checkbox"
                          defaultChecked={Method.auto_payment_date}
                          onClick={() => setMitZahlungsDatum(!mitZahlungsDatum)}
                        />

                        <button
                          className="abbrechen"
                          onClick={() => setInpurtTextMethods("")}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                        <button className="speichern" onClick={editVerkausaArt}>
                          <i className="fa-solid fa-check"></i>
                        </button>
                      </div>
                    ) : (
                      <div className="text">{Method.title}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="addArtikelBzBox">
            <input
              type="text"
              value={addVerkaufsArtinputText}
              placeholder="Neuen Verkauf Art hinzufügen"
              onChange={(e) => setaddVerkaufsArtinputText(e.target.value)}
            />
            <button onClick={addVerkaufsArt}> Add </button>
          </div>
        </div>

        <div className="bearbeitenForm ">
          <div className="titleTickets"> Tickets bearbeiten</div>
          <div className="editInfos">
            <div className="felderBox ArtikelArt">
              <label className="label">Artikel Art</label>
              <input
                type="text"
                onChange={(e) => setArtikelArt(e.target.value)}
              />
            </div>
            <div className="felderBox Start">
              <label className="label">Artikel Nr - von:</label>
              <div className="cont">
                <input
                  type="text"
                  value={StartArtNr}
                  onChange={(e) => setStartArtNr(e.target.value)}
                />
                <label>bis:</label>
                <input
                  type="text"
                  value={EndArtNr}
                  onChange={(e) => setEndArtNr(e.target.value)}
                />
                <label>gleich:</label>
                <input type="text" value={gleich} onChange={() => {}} />
              </div>
            </div>
          </div>

          <div className="felderBox Artikelbezeichnung">
            <label className="label">Artikelbezeichnung</label>
            <select
              ref={ArtikelbezeichnungRef}
              onChange={(e) =>
                e.target.value === "Alle" ? setEditBtn(true) : setEditBtn(false)
              }
            >
              <option value="Alle" key={0}>
                Alle
              </option>
              {products.map((product, i) => {
                return (
                  <option value={product.id} key={product.id}>
                    {product.title}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="felderBox Erhaltsdatum">
            <label className="label">Verkaufs-Datum</label>
            <Flatpickr
              className="calendar"
              value={VerkaufDatum}
              onChange={(date) => setVerkaufDatum(date[0])}
              options={{
                enableTime: false,
                dateFormat: "Y-m-d",
                altInput: false,
                altFormat: "F j, Y",
                time_24hr: false,
                locale: German,
              }}
            />
          </div>
          <div className="felderBox Erhaltsdatum">
            <label className="label">Zahlungs-Datum</label>
            <Flatpickr
              className="calendar"
              value={ZahlungsDatum}
              onChange={(date) => setZahlungsDatum(date[0])}
              options={{
                enableTime: false,
                dateFormat: "Y-m-d",
                altInput: false,
                altFormat: "F j, Y",
                time_24hr: false,
                locale: German,
              }}
            />
          </div>
          <div className="felderBox Erhaltsdatum">
            <label className="label">Storno/Retour-Datum</label>
            <Flatpickr
              className="calendar"
              value={StornoRetourDatum}
              onChange={(date) => setStornoRetourDatum(date[0])}
              options={{
                enableTime: false,
                dateFormat: "Y-m-d",
                altInput: false,
                altFormat: "F j, Y",
                time_24hr: false,
                locale: German,
              }}
            />
          </div>
          <div className="felderBox Erhaltsdatum">
            <label className="label">Verlust-Datum</label>
            <Flatpickr
              className="calendar"
              value={VerlustDatum}
              onChange={(date) => setVerlustDatum(date[0])}
              options={{
                enableTime: false,
                dateFormat: "Y-m-d",
                altInput: false,
                altFormat: "F j, Y",
                time_24hr: false,
                locale: German,
              }}
            />
          </div>

          <div className="felderBox">
            <label className="label">eVB Nr.</label>
            <input
              type="text"
              value={eVB}
              onChange={(e) => {
                seteVB(e.target.value);
              }}
            />
          </div>
          <div className="felderBox">
            <label className="label">Netto</label>
            <input
              type="text"
              value={Netto}
              onChange={(e) => {
                setNetto(e.target.value);
              }}
            />
          </div>
          <div className="felderBox">
            <label className="label">Vers. Steuer</label>
            <input
              type="text"
              value={Steuer}
              onChange={(e) => {
                setSteuer(e.target.value);
              }}
            />
          </div>

          <div className="felderBox">
            <label className="label">Brutto</label>
            <input
              type="text"
              value={Brutto}
              onChange={(e) => {
                setBrutto(e.target.value);
              }}
            />
          </div>
          <div className="felderBox">
            <label className="label">Verkaufpreis</label>
            <input
              type="text"
              value={Verkaufspreis}
              onChange={(e) => {
                setVerkaufspreis(e.target.value);
              }}
            />
          </div>
          <div className="felderBox">
            <label className="label">Verkaufs. Art</label>
            <select ref={VerkaufsArtRef}>
              <option value="Alle" key={0}>
                Alle
              </option>
              {paymentMethods.map((method, i) => {
                return (
                  <option value={method.id} key={i}>
                    {method.title} {method.auto_payment_date ? "✔️" : null}{" "}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="btnBox ">
            <button
              className="Eintragen"
              onClick={Änderung}
              style={
                editBtn
                  ? { opacity: 0.5, cursor: "not-allowed" }
                  : { opacity: 1, cursor: "pointer" }
              }
              disabled={editBtn}
            >
              {änderungLoud ? (
                <i class="fa-solid fa-circle-notch"></i>
              ) : (
                "Änderung Speichern"
              )}
            </button>
            <button className="Reset" onClick={reset}>
              {resetLoud ? (
                <i class="fa-solid fa-circle-notch"></i>
              ) : (
                <>
                  Reset
                  <i className="fa-solid fa-arrows-rotate"></i>
                </>
              )}
            </button>
            <button className="löchen" onClick={löschen}>
              {deleteLoud ? (
                <i class="fa-solid fa-circle-notch"></i>
              ) : (
                <>
                  Löchen
                  <i className="fa-solid fa-trash-can"></i>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bearbeiten;
