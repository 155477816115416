import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//import reportWebVitals from './reportWebVitals';
import "./styles/globals.scss";
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from "./context/context";
import "react-notifications/lib/notifications.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ContextProvider>
      <App />
    </ContextProvider>
  </BrowserRouter>
);
